//products list
.products-wrapper {
  position: relative;

  &.orange {
    .checkbox-wrapper {
      [type="checkbox"]:not(:checked) + label:after,
      [type="checkbox"]:checked + label:after {
        content:url(
          'data:image/svg+xml;utf-8,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" fill="rgb(255, 95, 31)" stroke="white" stroke-width="2"></circle></svg>'
        );
      }
    }

    .product-details, .selected-product-details {
      &:hover {
        color: $color-orange;
      }
    }
  }

  &.blue {
    .checkbox-wrapper {
      [type="checkbox"]:not(:checked) + label:after,
      [type="checkbox"]:checked + label:after {
        content:url(
          'data:image/svg+xml;utf-8,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" fill="rgb(2,195,255)" stroke="white" stroke-width="2"></circle></svg>'
        );
      }
    }

    .product-details, .selected-product-details {
      &:hover {
        color: $color-blue;
      }
    }
  }

  &.purple {
    .checkbox-wrapper {
      [type="checkbox"]:not(:checked) + label:after,
      [type="checkbox"]:checked + label:after {
        content:url(
          'data:image/svg+xml;utf-8,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" fill="rgb(117, 110, 229)" stroke="white" stroke-width="2"></circle></svg>'
        );
      }
    }

    .product-details, .selected-product-details {
      &:hover {
        color: $color-purple;
      }
    }
  }

  &.green {
    .checkbox-wrapper {
      [type="checkbox"]:not(:checked) + label:after,
      [type="checkbox"]:checked + label:after {
        content:url(
          'data:image/svg+xml;utf-8,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" fill="rgb(124,252,0)" stroke="white" stroke-width="2"></circle></svg>'
        );
      }
    }

    .product-details, .selected-product-details {
      &:hover {
        color: $color-green;
      }
    }
  }

  &.violet {
    .checkbox-wrapper {
      [type="checkbox"]:not(:checked) + label:after,
      [type="checkbox"]:checked + label:after {
        content:url(
          'data:image/svg+xml;utf-8,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" fill="rgb(227, 74, 94)" stroke="white" stroke-width="2"></circle></svg>'
        );
      }
    }

    .product-details, .selected-product-details {
      &:hover {
        color: $color-violet;
      }
    }
  }


  .products-type {
    display: inline-block;
    position: fixed;
    right: 0;
    padding: 23px 23px 0 0;
    z-index: 2;

    &.map-style-display {
      padding: 15px 30px;
      background: rgba(255, 255, 255, 0.95);
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
      border-radius: 60px;
      margin-top: 10px;
      margin-right: 20px;
    }

    div {display: inline-block;}

    label {
      margin-left: 10px;
      font-family: "DIN W01 Medium", sans-serif;
      font-size: 13px;
      line-height: 16px;
      padding-top: 5px;
      color: $color-black-text;
    }

    input[type="radio"] {
      margin-top: 7px;
    }

    .search-wrapper {
      display: inline-flex;
      vertical-align: text-top;
      margin-right: 78px;

      .search-input-field {
        border-radius: 60px;
        max-width: 130px;
        width: 100%;
        height: 15px;
        max-height: 100%;

        &:focus-visible {
          outline: none;
        }

        &:focus {
          color: $color-dark-gray;
          border-color: $color-light-gray;
          box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
        }
      }
  
      label {
        margin-right: 10px;
      }
    }
  }

  .selected-products {
    .products-table {
      font-size: 15px;
      line-height: 22px;
      padding: 24px 0 0 57px;

      .radio-input {
        span {
          font-size: 13px;
          line-height: 22px;
          padding-left: 40px;
          font-family: "DIN W01 Medium", sans-serif;
        }
      }

      .products-sort {
        padding: 25px 0 0 35px;
        
        button {
          outline: none;
          border: none;
          background: none;
          text-decoration: underline;
          color: $color-black-text;
          font-size: 13px;
          line-height: 22px;
          text-align: left;
        }

        .sort-name {
          max-width: 390px;
          width: 100%;
        }

        .sort-address {
          max-width: 397px;
          width: 100%;
        }

        .sort-owner {
          max-width: 150px;
          width: 100%;
        }
      }

      .campaigns-list {
        padding-top: 28px;
        margin-left: -13px;
      }
  
      .products-list {
        max-height: calc(100vh - 308px);
        overflow: auto;
        margin-top: 33px;

        &.location, &.power-btn, &.wifi-list {
          .product-type {
            padding-left: 0;
          }
        }
  
        ul {
          list-style-type: none;
          padding-left: 0;
          overflow: hidden;

          .product-header {
            span {
              color: $color-gray-text;
              font-weight: 300;
            }

            .product-header-element {
              max-width: 95px;
              width: 100%;
            }

            .product-header-element-nick {
              max-width: 135px;
              width: 100%;
              padding-right: 0;
            }

            .product-header-element-ssid {
              max-width: 275px;
              width: 100%;
            }
          }
    
          li {
            padding-top: 16px;

            &:first-child {
              padding-top: 0;
            }

            .product-details {
              border: none;
              outline: none;
              background-color: $white;
              margin-right: 25px;
              text-decoration: underline;
            }
    
            span {
              font-size: 15px;
              line-height: 22px;
              padding-right: 40px;
              color: $color-black-text;
              display: inline-block;
            }
    
            .product-type {
              max-width: 150px;
              width: 100%;
              padding-left: 40px;
            }

            .product-name-table {
              max-width: 120px;
              width: 100%;
            }
    
            .product-number {
              max-width: 95px;
              width: 100%;
            }

            .product-nickname-label {
              max-width: 95px;
              width: 100%;
            }

            .product-address {
              max-width: 390px;
              width: 100%;
              padding-right: 20px;
            }

            .display-address {
              max-width: 250px;
              width: 100%;
              padding-right: 20px;
            }

            .product-nickname {
              font-style: italic;
              border-radius: 15px;
              border: 1px solid $color-gray-text;
              padding: 5px;
              margin-right: 10px;
              
              &:hover, &:active, &:focus {
                outline: none;
              }
            }

            .product-lat, .product-long {
              max-width: 95px;
              width: 100%;
            }

            .product-location {
              max-width: 275px;
              width: 100%;
            }

            .campaign-name {
              padding-left: 30px;
              max-width: 180px;
              width: 100%;

              &.archived {
                span {
                  color: $color-violet;
                }
              }
            }

            .campaign-screens {
              max-width: 160px;
              width: 100%;
            }

            .campaign-date {
              max-width: 160px;
              width: 100%;
            }

            .campaign-status {
              max-width: 70px;
              width: 100%;
            }

            .product-group {
              max-width: 200px;
              width: 100%;
            }

            .product-ratio {
              max-width: 20px;
              width: 100%;
            }

            .product-error {
              font-size: 12px;
              line-height: 13px;
              color: $color-modal-title-warning;
              display: inline-block;
              margin-left: 5px;
            }

            .product-success {
              font-size: 12px;
              line-height: 13px;
              color: $color-green;
              display: inline-block;
              margin-left: 5px;
            }

            .geo-data-error {
              font-size: 12px;
              line-height: 13px;
              color: $color-modal-title-warning;
              display: block;
              margin-left: 400px;
              margin-top: 5px;
            }

            .save-btn {
              display: inline-block;
              padding: 0;

              button {
                margin-bottom: 5px;
                margin-right: 5px;
                font-family: "DIN W01 Regular", sans-serif;
              }

              .rounded--button {
                background-color: $color-blue;
                border: none;
            
                &:hover {
                  background-color: $color-blue-hover;
                }
            
                a {
                  text-transform: initial;
                }
              } 
            }

            .power-btn-state {
              padding-left: 15px;

              [type="checkbox"]:not(:checked) + label:after,
              [type="checkbox"]:checked + label:after {
                content:url('data:image/svg+xml;utf-8,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" fill="rgb(2, 195, 255)" stroke="white" stroke-width="2"></circle></svg>'
                );
              }
            }
          }
        }
      }
    }
  }

  .products-map {
    display: block;
    width: 100%;
    height: 100vh;
  
    .gm-style-mtc, 
    .gm-svpc, 
    .gm-style-cc {
      display: none !important;
    }

    .gmnoprint {
      border-radius: 35px !important;

      div {
        border-radius: 25px !important;

        button {
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

.displays-map {
  margin-top: -120px;
}

.products__footer-control-bar {
  bottom: 0;
  width: 100%;
  max-width: calc(100% - 325px);
  position: fixed;
  padding: 20px 0;

  &.products-info__footer {
    padding: 0;
    background-color: $white;
    height: 80px;

    .products-counter {
      margin-top: 30px;
    }
  }

  .products-counter {
    font-family: 'DIN W01 Medium', sans-serif; 
    float: left;
    margin-left: 57px;

    &.map-style-display {
      padding: 15px 30px;
      background: rgba(255, 255, 255, 0.95);
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
      border-radius: 60px;
    }
    
    .selected-product-details {
      font-family: 'DIN W01 Regular', sans-serif;
      border: none;
      text-decoration: underline;
      background-color: $white;
      outline: none;
      margin-left: 19px;
      font-size: 15px;
      font-weight: 300;
    }
  }

  .products__view-switcher {
    text-align: right;
    float: right;
    margin-right: 20px;

    .radio-input {
      display: inline-block;
    }

    &.map-style-display {
      padding: 14px 27px;
      background: rgba(255, 255, 255, 0.95);
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
      border-radius: 60px;
    }
  }

  .export-files {
    text-align: right;
    margin-top: 30px;

    button {
      background: none;
      border: none;
      font-size: 15px;
      line-height: 24px;
      padding-right: 20px;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      span {
        padding-left: 5px;
        vertical-align: bottom;
        
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  .orange {           
    color: $color-orange;
  }
  
  .blue {           
    color: $color-blue;
  }

  .purple {           
    color: $color-purple;
  }

  .green {
    color: $color-green;
  }

  .radio-input {
    span {
      padding-right: 30px;
    }

    input {
      margin-top: 10px;
    }
  }
}

.time__period-selector {
  margin-left: 57px;
  padding-top: 23px;
  font-size: 13px;
  line-height: 16px;
  font-family: "DIN W01 Medium", sans-serif;
  text-align: right;
  margin-right: 20px;

  .radio-input, .date-display {
    display: inline-block;
  }

  .date-display {
    left: 0;
    position: absolute;
    font-size: 18px;
    line-height: 22px;
    color: $color-gray-text;
    padding-left: 57px;
  }

  .custom__date-picker {
    display: inline-flex;
    position: absolute;
    right: 35px;
    top: 50px;

    .date-label {
      span {
        color: $color-dark-gray;
        font-size: 13px;
        width: 65px;
        display: block;
        padding-top: 16px;
        padding-left: 10px;
      }
    }

    .react-datepicker-wrapper {
      padding: 20px 0 20px 10px;
      font-size: 18px;
      line-height: 22px;
      color: $color-gray-text;

      .react-datepicker__input-container {
        input {
          color: $color-gray-text;
          border-radius: 30px;
          border: 1px solid;
          max-width: 115px;
          padding-left: 5px;
          font-family: 'DIN W01 Regular', sans-serif;

          &:focus-visible {
            outline: none;
          }
        }
      }
    }
    .react-datepicker__tab-loop {
      .react-datepicker-popper {
        .react-datepicker {
          .react-datepicker__day--in-selecting-range,
          .react-datepicker__month-text--in-selecting-range,
          .react-datepicker__quarter-text--in-selecting-range,
          .react-datepicker__year-text--in-selecting-range {
            background-color: $color-orange;
          }
        }
      }
    }

    &.green {
      .react-datepicker__tab-loop {
        .react-datepicker-popper {
          .react-datepicker {
            .react-datepicker__day--in-selecting-range,
            .react-datepicker__month-text--in-selecting-range,
            .react-datepicker__quarter-text--in-selecting-range,
            .react-datepicker__year-text--in-selecting-range {
              background-color: $color-green;
            }
          }
        }
      }
    }
  }
}

.sensors__info-wrap, .distributor__info-wrap {
  margin-bottom: 80px;
}

.main-chart-wrapper {
  margin-left: 57px;

  .product-details {
    border: none;
    outline: none;
    background-color: $white;
    margin-left: 16px;
    text-decoration: underline;
    font-size: 15px;
    color: $color-mid-gray;
  }

  .chart-title {
    margin-top: 92px;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
  }

  .chart-info {
    div {
      margin-top: 10px;

      .aqi-aggregates {
        margin-top: 40px;

        &:last-child {
          padding-bottom: 10px;
        }
      }

      .label {
        max-width: 210px;
        width: 100%;
        display: inline-block;
        font-size: 15px;
        line-height: 22px;
        color: $color-black-text;

        &.long-label {
          max-width: 280px;
        }

        span {
          font-size: 15px;
          line-height: 22px;
          color: $color-black-text;
        }
      }

      .value {
        max-width: 110px;
        width: 100%;
        font-size: 18px;
        line-height: 22px;
        font-family: "DIN W01 Medium", sans-serif;
        display: inline-block;
        color: $color-orange;
        
        &.green {
          color: $color-green;
        }

      }

      .date {
        font-size: 15px;
        line-height: 22px;
        color: $color-gray-text;
        font-family: "DIN W01 Medium", sans-serif;
      }
      
      .value-aqi { 
        padding-right: 25px;
        max-width: 150px;
        width: 100%;
        display: inline-block;
        text-align: end;
      }

      .circle-color {
        vertical-align: sub;
      }

      .text-label {
        padding-left: 15px;
        font-size: 15px;
        font-family: "DIN W01 Medium", sans-serif;
      }

      .date-aqi {
        font-family: "DIN W01 Medium", sans-serif;;
        margin: 0;
        color: $color-dark-gray;
        font-size: 15px;
        line-height: 10px;
      }
    }
  }
  
  .chart-wrapper {
    margin: 20px 60px 30px 0;
    height: 100%;
    max-height: 400px;
    display: flex;
    // border: 0.5px solid $color-gray-border;
    box-sizing: border-box;

    .highcharts-credits {
      display: none;
    }
  }
}

.highcharts-legend-item {
  display: none;
}

.control-bar-selector {
  margin-right: 20px;
  padding-top: 23px;
  font-size: 13px;
  line-height: 16px;
  font-family: "DIN W01 Medium", sans-serif;
  text-align: right;

  [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
    color: $black;

    &::first-letter {
      text-transform: capitalize;
    }
  }
}

.requested-label {
  font-size: 13px !important;
}

.products-table.power-btn-products {
  .save-btn {
    position: fixed;
    bottom: 35px;
    padding-top: 0;
    
    .rounded--button {
      background-color: $color-blue;
      border: none;
  
      &:hover {
        background-color: $color-blue-hover;
      }
  
      a {
        text-transform: initial;
      }
    } 
  }
}

.products__footer-control-bar.products-info__footer {
  &.orange {
    .selected-product-details {
      &:hover {
        color: $color-orange-hover;
      }
    }
  }

  &.blue {
    .selected-product-details {
      &:hover {
        color: $color-blue-hover;
      }
    }
  }

  &.purple {
    .selected-product-details {
      &:hover {
        color: $color-purple-hover;
      }
    }
  }

  &.green {
    .selected-product-details {
      &:hover {
        color: $color-green-hover;
      }
    }
  }
}

.table-padding {
  padding-top: 46px;
}

// Small-screen
@include media-breakpoint-down(sm) {

  .products-wrapper {
    display: inline-block;
    padding-top: 0;
    width: 100%;
    margin-top: 50px;

    .products-type {
      position: inherit;

      &.map-style-display {
        padding: 0;
        background: none;
        box-shadow: none;
        border-radius: 0;
        margin-top: 0;
        margin-right: 0;
      }
    }

    .selected-products {
      margin-top: 0;

      .products-table {
        padding: 70px 0 0;

        .products-list {
          ul {

            .product-header {
              display: none;
            }

            li {
              .product-type, .product-address {
                display: none;
              }

              .product-number {
                padding-left: 40px;
              }

              .product-nickname, .product-lat, .product-long, .product-location { 
                display: block;
                margin: 10px 0;
              }

              .geo-data-error {
                margin-left: 10px;
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
  }

  .products-type {
    width: 100%;
    text-align: center;
  }

  .products__footer-control-bar {
    max-width: 100%;
    position: relative;

    .products__view-switcher {
      float: left;

      &.map-style-display {
        padding: 0;
        background: none;
        box-shadow: none;
        border-radius: 0;
        margin-top: 0;
        margin-right: 0;
      }
    }

    .products-counter {
      margin-left: 10px;

      &.map-style-display {
        padding: 0;
        background: none;
        box-shadow: none;
        border-radius: 0;
        margin-top: 0;
        margin-right: 0;
      }
    }
  }

  .time__period-selector {
    padding-top: 65px;
    margin-left: 0;
    margin-right: 10px;
    text-align: end;

    .date-display {
      position: relative;
    }
  }

  .main-chart-wrapper {
    margin-left: 5px;
  
    .chart-wrapper {
      margin: 5px;
      margin-left: 0;
    }

    .chart-info {
      div {
        .value-aqi {
          text-align: left;
          max-width: 100%;
        }
        .date-aqi {
          margin-top: 10px;
        }
      }
    }
  }

  .control-bar-selector {
    display: inline-block;
    padding-top: 80px;
  }

  .products-table.power-btn-products {
    .save-btn {
      display: inline-block;
      padding-top: 30px;
      position: relative;
      bottom: 0;
    }
  }
  
  .power-btn-state {
    padding-left: 0;
  }

  .marker-label {
    margin-left: -65px !important;
    margin-top: -65px;
    background: rgba(150, 150, 150, 0.95);
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    width: 120px !important;
    height: 47px;
    color: rgba(255, 255, 255, 0.95) !important;
    font-family: "DIN W01 Regular" !important;
    font-size: 10px !important;
    line-height: 22px;
    text-align: left;
    padding: 5px !important;
    white-space: pre;
    position: absolute;
    z-index: 999999999999;
  } 
  
}

.marker-label {
  margin-left: -195px;
  margin-top: -65px;
  background: rgba(150, 150, 150, 0.95);
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
  width: 143px;
  height: 47px;
  color: rgba(255, 255, 255, 0.95) !important;
  font-family: "DIN W01 Regular" !important;
  font-size: 15px !important;
  line-height: 22px;
  text-align: left;
  padding: 10px 20px;
  white-space: pre;
  position: absolute;
  z-index: 999999999999;
} 

//PDF-export
@media print {
  .sensors__info-wrap, 
  .distributor__info-wrap {
    .sensors__info-print-only-title.hidden,
    .distributor__info-print-only-title.hidden {
      display: block;
      text-align: center;
      text-transform: uppercase;
    }

    .main-chart-wrapper {
      padding-left: 50px;

      .chart-title {
        margin-top: 50px;
      }

      .chart-wrapper {
        border: none;
      }
    }
  
    .time__period-selector {
      padding-top: 0;

      .date-display {
        float: left;
      }

      .radio-input {
        visibility: hidden;
      }
    }
  }

  @page {
    size: 420mm;
    display: block;
    page-break-before: auto;
    margin-top: 100px;
  }
}
