.intro-container {
  padding-top: 110px;
  text-align: left;
  margin: 0 auto;
  max-width: 665px;
  width: 100%;

  .page-title {
    max-width: 665px;
    width: 100%;
  }

  .page-description {
    max-width: 665px;
  }

  .button-container {
    text-align: right;
  }
}

.welcome-wrapper {
  height: 100vh;
}

.welcome-page-icons {
  margin: 123px 440px 0;
}

//Login page,Forgot password page
.modal-dialog {
  max-width: 665px;
  width: 100%;
}


.modal-backdrop { 
  background-color: $black;
  opacity: 0.3;
}

.modal-error_container {
  width: 443px;
  margin: 40px 0 20px;
}

.terms-checkbox {
  margin-left: -10px;
  margin-top: 20px;
  
  a {
    margin-left: 5px;

    img {
      width: 15px;
      height: 15px;
      vertical-align: text-top;
    }
  }

  .terms-label {
    margin-left: 35px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    vertical-align: text-top;
  }
}

.form-container {
  margin: 100px auto;

  .input-error {
    font-size: 12px;
    line-height: 13px;
    color: $color-modal-title-warning;
    margin-bottom: -5px;
    display: block;
    margin-top: 10px;
  }

  .form-content {
    margin: 0 auto;
    max-width: 665px;
    padding: 35px 110px;
    border: 1px solid $color-light-gray;
    box-shadow: 0 24px 50px $color-light-gray;

    .form-title {
      font-size: 22px;
      line-height: 27.3px;
      text-transform: uppercase;
      font-family: "DIN W01 Medium", sans-serif;
    }

    .form-input {
      padding-top: 20px;

      &.code-input-form {padding-top: 80px;}
      

      .input-squared {
        max-width: 426px;
        width: 100%;
        max-height: 36px;
        height: 100%;

        .css-2b097c-container {
          width: 438px;
        }

        input {
          width: 100%;
          height: 100%;
          padding: 5px;
          border: 1px solid $black;
          border-radius: 0;
          
          &:focus,
          &:active {
            outline: none;
            box-shadow: none;
          }
        }

        .country-selector {
          div {
            border-color: black;
            border-radius: 0;
          }

          &.css-b62m3t-container {
            margin-right: -13px;
          }
        }

        #password,
        .password-input-field {
          background: $color-light-gray;
        }
      }
    }

    .privacy-checkbox {
      margin-left: -10px;
      margin-top: 20px;
      
      a {
        margin-left: 5px;

        img {
          width: 15px;
          height: 15px;
          vertical-align: text-top;
        }
      }

      .privacy-label {
        margin-left: 35px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        vertical-align: text-top;
      }
    }

    .account-links {
      padding-top: 22px;
      font-size: 18px;
      line-height: 32px;

      div {
        padding-top: 5px;
      }

      .checkbox-wrapper {
        p {
          color: $black;
          display: inline-block;
        }

        &.registration-type {
          padding: 5px 0 50px;
          display: inline-block;
          width: 50%;

          input {
            margin-left: 10px;
          }
        }
      }
    }

    .code-input-btn {
      padding-bottom: 108px;

      button {
        width: 147px;
        height: 52px;
      }
    }

    .login-btn,
    .send-email-btn {
      display: block;
      text-align: end;
      padding-top: 20px;
    }

    .close-btn {
      margin-right: -80px;
      text-align: right;
    }
  }
}

.clickable-text {
  font-size: 15px;
  line-height: 22px;
  text-align: right;
  text-decoration: underline;
  margin-top: 20px;
  // text-transform: uppercase;
}


// checkbox
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: 0;
  opacity: 0.01;
  display: none;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  cursor: pointer;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background: $white;
  border: 1px solid $color-checkbox-gray;
  box-sizing: border-box;
  border-radius: 25px;
  left: 9px;
  top: -7px;
}

/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content:url('../icons/checkCircle.svg');
  position: absolute;
  top: -6px;
  left: 10px;
  font-size: 1.375em;
  line-height: 0;
  -webkit-transition: all .2s;
    transition: all .2s;
}

/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
}

[type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
}

.cookies__bar {
  position: fixed;
  width: 100%;
  background-color: $white;
  color: $color-black-text;
  bottom: 0;
  left: 0;
  opacity: 1;
  font-size: 15px;
  z-index: 9999;
  box-shadow: 0px -1px 10px 0px rgba(172, 171, 171, 0.3);

  .cookies__bar-container {
    padding: 20px 25px;
    display: flex;
    justify-content: space-between;

    .cookies__bar-action-buttons {
      button {
        display: inline-block;
        color: $white;
        background-color: $black;
        margin-left: 10px;
      }

      .cookies-info__btn {
        background-color: transparent;
        color: $black;
        border: none;
        text-decoration: underline;

        &:focus {
          outline: none;
          border: none;
        }
      }
    }
  }
}


//smaller screens
@include media-breakpoint-only(xs) {
  .page-title {
    font-size: 25px;
    max-width: 300px;
  }

  .page-description {
    font-size: 12px;
    max-width: 100%;
  }

  .welcome-page-icons {
    margin: 50px 10px;
  }

  .intro-container {
    max-width: 300px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 50px;
  }
}

// Small-screen
@include media-breakpoint-down(sm) {

  .modal-dialog {
    margin-top: 25%;
    max-width: calc(100% - 20px);
  }
  
  .form-container {
    margin: 50px auto;

    .form-content {
      padding: 25px;

      .close-btn {
        margin-right: 0;
      }

      .form-input {
        margin-right: 60px;
      }
    }
  }

  .form-input .input-squared .css-2b097c-container {
    width: 250px !important;
  }

  .form-container .form-content .send-email-btn {
    padding-top: 25px;
  }

  .welcome-wrapper {
    height: auto;
  }
  
  .cookies__bar-container {
    .cookies__bar-action-buttons {
      button {
        margin-top: 5px;
      }
    }
  }
}