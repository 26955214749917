.shared-link__header {
  margin-left: 60px;
  
  p {
    color: $color-black-text;
    font-size: 22px;
    line-height: 27px;
  }
}

.shared-link-container {
  margin-left: 125px;
  margin-top: 20px;

  .shared-link__row {
    padding-top: 60px;

    .row-title {
      font-size: 13px;
      line-height: 16px;
      color: $color-black-text;
      display: inline-block;
      max-width: 170px;
      width: 100%;
    }

    .row-value, .row-value-list {
      font-size: 15px;
      line-height: 19px;
      font-family: "DIN W01 Medium", sans-serif;
      color: $color-black-text;
    }

    .list-info {
      list-style-type: none;
      margin-left: 155px;
      margin-top: -23px;
    }

    .row-value-list {
      padding-bottom: 5px;
      
      &:last-child {
        padding-bottom: 0;
      }

      .name,.device-id,.location,.status {
        font-size: 15px;
        line-height: 22px;
        font-family: "DIN W01 Regular", sans-serif;
        display: inline-block;
      }

      .name,.device-id {
        max-width: 150px;
        width: 100%;
      }

      .status {
        max-width: 100px;
        width: 100%;
      }

      .location {
        max-width: 230px;
        width: 100%;
      }
    }
  }
}

.shared-link__footer {
 text-align: right;
 padding-top: 100px;
 padding-bottom: 20px;

  span {
    font-size: 12px;
    line-height: 15px;
    padding-right: 30px;
  }
}

.api-doc__component {
  .doc-container {
    margin: 10px 10px 10px 50px;
    max-width: 100%;

    .wrapper {
      border-right: 1px solid $color-light-gray;
      height: calc(100vh - 200px);

      .row {
        h3 {padding-left: 10px;}
      }

      &:last-child {
        border: none;
      }

      .download-json {
        border: none;
        background-color: transparent;
        font-size: 15px;
        margin-top: 10px;
        padding-left: 10px;
       
        &:hover,
        &:active,
        &:focus {
          outline: none;
          box-shadow: none;         
          text-decoration: underline;
        }
      }

      .menu-wrapper {
        padding-top: 24px;

        ul {
          list-style-type: none;

          li {
            padding: 10px;

            button {
              border: none;
              background-color: transparent;
              font-size: 15px;

              &:hover,
              &:active,
              &:focus {
                outline: none;
                box-shadow: none;
                text-decoration: underline;
              }
            }
          }
        }
      }

      .menu-item__wrapper {
        padding-top: 35px;
        padding-right: 50px;

        .api-parameters {
          h5,h6 {padding-top: 20px;}

          .api-route {
            font-size: 13px;
            color: $black;
            background-color: $color-light-gray;
            border-radius: 5px;
            padding: 3px;
          }

          .table-header {
            border-bottom: 1px solid $color-light-gray;

            span {
              font-size: 13px;
              font-weight: 600;
              color: $black;
              width: 100px;
              display: inline-block;
            }
          }

          .table-values {
            border-bottom: 1px solid $color-light-gray;
            margin: 5px 0;

            span {
              font-size: 13px;
              color: $black;
              display: inline-block;
            }

            .parameter {
              background-color: $color-light-gray;
              border-radius: 5px;
              padding: 3px;
              margin-right: 60px;
              margin-bottom: 5px;

              &.serial-number {margin-right: 19px;}
              &.start-date {margin-right: 45px;}
              &.end-date {margin-right: 51px;}
            }
           

            .status {
              width: 100px;
            }
          }
        }
      }

      .response-wrapper {
        h4 {
          background-color: $color-light-gray;
          border-radius: 5px;
          padding: 3px
        }

        span {
          font-size:13px;
        }

        padding-top: 35px;
        max-height: calc(100% - 50px);
        overflow: auto;
      }
    }
  }
}

// Small-screen
@include media-breakpoint-down(sm) {
  .shared-link__header,.shared-link-container,.shared-link__footer {
    margin-left: 10px;
    margin-right: 10px;
  }

  .shared-link-container {
    .shared-link__row {
      .row-title, .row-value {
        max-width: calc(100% - 30px) !important;
        display: block;
      }

      .list-info {
        margin-top: 0;
        margin-left: 0;

        .row-value-list {
          span {
            display: inline-block;
            max-width: fit-content;
            padding-right: 15px;
          }
        }
      }
    }
  }

  .shared-link__footer {
    text-align: left;

    span {
      display: inline-block;
    }
  }

  .api-doc__component {
    .doc-container {
      margin-left: 10px;
      max-width: 90%;

      .wrapper {
        height: auto;
        border-right: none;
      }
  
      .menu-item__wrapper {
        .api-parameters {
          .table-header {
            span {
              width: 100%;
              display: block;
            }
          }

          .table-values {
            span {
              display: block;
            }

            .parameter {
              margin-right: 5px;

              &.serial-number {margin-right: 10px;}
              &.start-date {margin-right: 10px;}
              &.end-date {margin-right: 10px;}
            }
          
            .status {
              width: 100%;
            }
          }
        }
      }
  
      .response-wrapper { 
        max-height: auto;
      }
    }
  }
}
