.footer {
  position: absolute;
  font-family: "DIN W01 Medium", sans-serif;
  bottom: 0;
  margin: 33px 0;
  width: 100%;
  text-align: end;

  span {
    font-size: 13px;
    line-height: 16px;
    color: $black;
    padding-right: 75px;
  }

  &.dark, &.mid {
    span {
      color: $white;
    }
  }

  &.light {
    span {
      color: $black;
    }
  }
}
