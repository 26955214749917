.sidebar, .mobile-menu {
  // max-width: 226px;
  max-width: 276px;
  width: 100%;
  height: 100vh;
  text-align: right;
  text-transform: uppercase;
  position: fixed;
  padding-right: 49px;

  .app-title {
    margin-top: 16px;
    font-size: 22px;
    line-height: 27px;
    font-family: "DIN W01 Medium", sans-serif;
  }

  .list-wrapper {
    padding-top: 100px;

    ul {
      list-style: none;

      .list-item-title {
        text-transform: lowercase;
        color: $color-gray-text;
        opacity: 0.6;
        font-style: italic;
      }

      li {
        font-size: 13px;
        font-family: "DIN W01 Medium", sans-serif;
        line-height: 22px;
        padding-top: 25px;

        &:first-child {
          padding-top: 0;
        }

        a {
          color: $color-gray-text;

          &.black {           
            &.active-route {
              color: $black;
              border-bottom: 1px solid $black;
            }
          }
          
          &.orange {           
            &.active-route {
              color: $color-orange;
              border-bottom: 1px solid $color-orange;
            }
          }
          
          &.blue {           
            &.active-route {
              color: $color-blue;
              border-bottom: 1px solid $color-blue;
            }
          }
          
          &.green {
            &.active-route {
              color: $color-green;
              border-bottom: 1px solid $color-green;
            }
          }
          
          &.violet {
            &.active-route {
              color: $color-violet;
              border-bottom: 1px solid $color-violet;
            }
          }
          
          &.yellow {            
            &.active-route {
              color: $color-yellow;
              border-bottom: 1px solid $color-yellow;
            }
          }

          &.purple {            
            &.active-route {
              color: $color-purple;
              border-bottom: 1px solid $color-purple;
            }
          }
        }
      }
    }
  }
}

// color identifier for sidebar
.color-sidebar {
  max-width: 49px;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1;

  .logo {
    width: 25px;
    height: 25px;
    margin: 16px 10px;
  }

  .exit-wrapper {
    bottom: 0;
    margin: 10px;
    position: absolute;

    svg {
      width: 27px;
      height: 27px;
    }
  }

  &.black {
    background: linear-gradient(351.34deg, rgba(255, 255, 255, 0) -28.66%, #383737 104.67%);
  }
  
  &.orange {
    background: linear-gradient(348.65deg, #FFA800 2.21%, #FF4701 112.16%);
  }
  
  &.blue {
    background: linear-gradient(348.65deg, #AEEB00 -9.36%, #01C2FF 91.49%);
  }
  
  &.green {
    background: linear-gradient(351.34deg, #FFDE00 -2.27%, #4ECC00 104.67%);
  }
  
  &.violet {
    background: linear-gradient(351.34deg, #FF4949 -28.66%, #954895 104.67%);
  }
  
  &.yellow {
    background: linear-gradient(351.34deg, #FF9901 -28.66%, #EFBA00 104.67%);
  }

  &.purple {
    background: linear-gradient(351.34deg, #49B3FF -28.66%, #BA00BA 104.67%);
  }
}

.mobile-menu, .hamburger-btn, .logo-link {
  display: none;
}

// Small-screen
@include media-breakpoint-down(sm) {
  .sidebar {
    display: none;
  }

  .hide-menu {
    background: none;
    display: none;
    z-index: 0 !important;
  }

  .show-menu {
    background: $white;
    display: block;
    z-index: 3;
  }

  .logo-link {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 25px;
    height: 25px;
    top: 14px;
    left: 10px;
  }

  .hamburger-btn {
    display: inline-block;
    border: none;
    outline: none;
    background-color: $white;
    width: 25px;
    height: 25px;
    margin-top: 11px;
    position: absolute;
    right: 0;
    z-index: 5;

    &:focus {
      outline: none;
      color: $white;
      box-shadow: none;
    }
  }

  .mobile-menu {
    display: block;
    max-width: 100%;
    padding-right: 0;
    text-align: center;
    position: absolute;
    background: $white;
    z-index: 3;

    .orange {           
      color: $color-orange;
    }

    .blue {           
      color: $color-blue;
    }
  }

  .color-sidebar {
    display: none;
  }
}