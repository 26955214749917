.app-wrapper {
  display: flex;
}

label {
  margin-bottom: 0.5rem;
}

// Main page of apps
.overview-wrapper {
  max-width: 664px;
  width: 100%;
  margin: 124px 0px 0px 67px;

  p {
    padding-top: 6px;
  }
}

.personal-settings,
.password,
.new-user-modal,
.edit-users,
.create-campaign-wrapper {
  padding-left: 75px;

  .displays-data-view {
    bottom: 0;
    position: fixed;
    padding: 20px;
    right: 0;
    z-index: 1;

    .displays-view {
      &.map-style-display {
        padding: 15px 30px;
        background: rgba(255, 255, 255, 0.95);
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
        border-radius: 60px;
      }
    }
  }

  .input-error {
    font-size: 12px;
    line-height: 13px;
    color: $color-violet;
    margin-bottom: -5px;
    display: block;
    margin-top: 10px;
    margin-left: 150px;

    &.no-margin-left {
      margin-left: 0;
    }
  }

  .profile-photo {
    padding-top: 140px;

    .profile-label {
      margin-left: 16px;
    }

    span {
      font-size: 13px;
      line-height: 19px;
      padding-right: 41px;
      color: $color-black-text;
    }

    .edit-link {
      vertical-align: bottom;
      text-decoration: underline;
      cursor: pointer;
    }

    .uploader-input {
      display: none;
    }

    img {
      width: 95px;
      height: 95px;
      border: 2px solid #FFFFFF;
      border-radius: 50px;
      margin-left: -10px;
    }
  }

  .profile-data {
    padding-top: 12px;
  }

  .input-label {
    font-size: 13px;
    line-height: 19px;
    padding-right: 41px;
    color: $color-black-text;
    vertical-align: text-top;
    text-align: right;
    width: 90px;
  }

  .input-app {
    max-width: 298px;
    height: 29px;
    width: 100%;
    border: 1px solid $color-silver;
    box-sizing: border-box;
    border-radius: 60px;
    padding: 3px 12px;
    font-size: 18px;
    line-height: 32px;
    display: inline-block;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .input-field {
    padding-top: 28px;

    &.disabled {
      opacity: 0.2;
    }
  }
}

.password-data {
  padding-top: 120px;
}

.two-fa {
  .input-field {
    padding-top: 25px;

      .custom-radio-button-left {
        label {
          font-size: 13px;
          line-height: 20px;
        }

        [type="radio"]:not(:checked) + label {
          font-size: 13px;
          line-height: 25px;
        }
      }
    }
  }

.password {
  .input-label {
    text-align: left;
    width: 158px;
  }

  .reminder {
    vertical-align: revert;
  }

  .qr-code-wrapper {
    padding: 31px 0 10px 200px;

    span {
      font-size: 13px;
      line-height: 16px;
      width: 150px;
      display: block;
    }

    .qr-code-data {
      padding-top: 25px;

      img {
        width: 125px;
        height: 125px;
        margin-left: -8px;
      }
    }

    .qr-code {
      padding-top: 24px;

      span {
        display: inline-block;
      }

      button {
        background: transparent;
        border: none;
        text-decoration: underline;
        font-size: 15px;
        line-height: 22px;
        display: inline-block;

        &:hover, &:active, &:focus {
          outline: none;
        }
      }
    }
  }
}

//select field
.select-field {
  display: inline-flex;
  max-width: 300px;
  width: 100%;

  .input-label {
    padding-top: 10px;
  }

  .css-2b097c-container,
  .css-yk16xz-control,
  .css-14jk2my-container,
  .css-1fhf3k1-control {
    max-width: 130px;
    max-height: 40px;
    height: 100%;
    border-radius: 60px !important;
    border: 1px solid $color-silver;
    width: 100%;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.save-btn {
  display: block;
  padding: 62px 0;
  text-align: left;

  .rounded--button {
    height: 28px;
    background-color: $color-dark-gray;
  }
}

.radio-btn {

  &.border-box {
    border: 1px solid $black;
    border-radius: 60px;
    text-align: center;
    height: 46px;
    padding-top: 15px;
    padding-left: 13px;
  }

  .radio-input {
    display: inline-block;
    margin: 0 25px;
    font-size: 13px;
    line-height: 20px;
  }
}

.coming-soon-app,
.error-page-wrapper {
  text-align: center;
  margin: 100px auto;
  
  p {  
    font-size: 70px;
    line-height: 80px;
  }
}

//USERS SETUP
.users {
  padding: 69px 0 0 70px;

  .users-filters {
    font-size: 13px;
    line-height: 19px;
    display: flex;
    margin-left: -28px;

    [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
      color: $color-black-text;
      line-height: 24px;
    }
  }
  
  .users-table {
    list-style-type: none;
    padding: 0;
    max-height: calc(100vh - 165px);
    overflow: auto;

    span {
      font-size: 13px;
      line-height: 19px;
      display: inline-block;
    }

    .name, .surname {
      max-width: 120px;
      width: 100%;
    }

    .email {
      max-width: 200px;
      width: 100%;
    }

    .rights, .user-active {
      max-width: 100px;
      width: 100%;
    }

    .users-table-header {
      color: $color-gray-text;
      margin-top: 37px;
      font-weight: 300;
    }
  
    .users-table-row {
      font-weight: 600;
      padding-top: 20px;
      
      .edit-button,.delete-button {
        margin-left: 105px;
  
        .edit-modal-button {
          border: none;
          background-color: transparent;
          text-decoration: underline;
          outline: none;
          text-transform: lowercase;

          &:hover {
            color: $color-dark-gray;
          }
        }
      }

      .delete-button {
        margin-left:0;
      }
  
      .inactive {
        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
          content:url('data:image/svg+xml;utf-8,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="6" r="7" fill="rgb(191, 191, 191)" stroke="white" stroke-width="2"></circle></svg>'
          );
          top: -3px;
        }
      }
  
      .active {
        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
          content:url('data:image/svg+xml;utf-8,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" fill="rgb(133, 172, 28)" stroke="white" stroke-width="2"></circle></svg>'
          );
        }
      }
    }
  }
}

.user-footer {
  padding-left: 65px;
  bottom: 0;
  position: fixed;
  font-size: 15px;
  max-width: calc(100% - 425px);
  width: 100%;

  button {
    border: none;
    background-color: transparent;
    outline: none;
    padding-right: 20px;
  }

  .new-user-btn {
    display: inline-block;
    text-decoration: underline;
    padding: 15px 0;
    
    button {
      text-decoration: underline;
    }
  }

  .setup-export-files {
    position: fixed;
    right: 0;
    bottom: 13px;
    margin-right: 20px;
  }
}

.user-permissions {
  margin-top: 35px;
}

.solos-app-content {
  margin-left: 325px;
  max-width: calc(100% - 325px);
  position: relative;

  .extra-space {
    padding-top: 20px;
  }

  .waste-edit-container {
    .password-data{
     padding-top: 20px;
    }}

  .sub-menu {
    max-width: 252px;
    width: 100%;
    position: fixed;
    text-align: right;
    left: 0;
    top: 240px;

    &.waste-menu {
      top:350px;
    }

    &.more-top {
      top:490px;
    }

    &.manage-routes {
      top: 260px;
    };
  
    .menu-item,
     .app-checkbox {
      text-align: right;
      padding-top: 20px;
  
      .menu-module {
        font-size: 13px;
        line-height: 22px;
        font-family: "DIN W01 Medium", sans-serif;
        text-transform: uppercase;
        color: $color-gray-text;

        &.orange {           
          color: $color-orange;
          border-bottom: 1px solid $color-orange;
        }

        &.green {           
          color: $color-green;
          border-bottom: 1px solid $color-green;
        }
      }
    }
  }

  .manage-menu {
    max-width: 280px;

    .module-list {
      list-style-type: none;
      padding: 0;

      button {
        background-color: $white;
        border: none;
        outline: none;
        text-transform: uppercase;
        font-size: 13px;
        font-family: "DIN W01 Medium", sans-serif;
        line-height: 22px;
        padding-top: 25px;
        color: $color-gray-text;

        &.blue { 
          text-decoration: underline $color-blue;
          color: $color-blue;
        }

        &.purple { 
          text-decoration: underline $color-purple;
          color: $color-purple;
        }
      }
    }
  }

  .displays-counter {
    padding-left: 70px;

    span {
      font-size: 18px;
      line-height: 22px;
    }

    .violet {
      color: $color-violet;
      font-family: "DIN W01 Medium";
    }
  }

  .devices-wrapper {
    margin: 135px 0 0 70px;

    h3 {
      font-size: 13px;
    }

    .devices-list {
      list-style-type: none;
      padding-left: 0;
      padding-right: 50px;
      margin-top: 35px;
      max-height: calc(100vh - 370px);
      overflow: auto;
      margin-left: -10px;

      .devices-header {
        span {
          display: inline-block;
          color: $color-gray-text;
          font-weight: 300;
          font-size: 13px;
          line-height: 19px;
        }
      }

      li {
        padding: 10px 0;
        
        span {
          font-size: 13px;
          line-height: 19px;
          color: $color-black-text;
          display: inline-block;
          font-weight: 600;
        }
      }
      
      .item { 
        max-width: 120px;
        width: 100%;
      }

      .date-item { 
        max-width: 170px;
        width: 100%;
        padding-left: 10px;
      }

      .current-session {
        color: $color-black-text;
        display: block;
        margin-left: 10px;
      }

      .browser-item {
        max-width: 195px;
        width: 100%;
      }

      .os-item {
        max-width: 160px;
        width: 100%;
      }

      .device-item {
        max-width: 100px;
        width: 100%;
      }

      .delete-btn {
        display: inline-block;
        outline: none;
        background: none;
        border: none;

        &.active-session {
          svg {
            fill: rgb(0,128,0);

            &:hover {
              fill: red;
              background-color: transparent;
            }
          }
        }
        
        svg {
          width: 20px;
          height: 20px;
          fill: $color-mid-gray;

          &:hover {
            fill: red;
          }
        }
      }
    }
  }

  .appearance-wrapper {
    margin: 144px 0 0 70px;

    h3 {
      font-size: 13px;
      line-height: 19px;
    }

    .api-key-wrapper {
      padding-top: 50px;
      
      .api-key {
        font-size: 13px;

        span {
          color: $color-black-text;
          font-size: 13px;
          width: 100%;
          display: inline-block;
          max-width: 150px;
        }

        button, .edit-modal-button {
          background: transparent;
          border: none;
          text-decoration: underline;
          font-size: 15px;
          line-height: 22px;
          display: inline-block;

          &:hover, &:active, &:focus {
            outline: none;
          }
        }
      }
    }

    .theme {
      margin-top: 65px;

      label {
        max-width: 85px;
        width: 100%;
        font-size: 13px;
        line-height: 21px;
        color: $black;
        vertical-align: bottom;
      }
    }

    .theme-picker {
      width: 122px;
      height: 23px;
      display: inline-block;

      &.dark-theme  {
        background-color: $black;
      }

      &.mid-theme  {
        background-color: $color-mid-gray-theme;
      }

      &.light-theme  {
        background-color: $white;
        border: 1px solid $color-light-gray;
      }
    }
  }

  .module-active {
    margin-top: 20%;
    text-align: center;
    font-size: 24px;

    .module-state {
        padding: 15px 30px;
        background: rgba(255, 255, 255, 0.95);
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
        border-radius: 60px;
        margin-top: 10px;
        margin-right: 20px;
        display: inline-block;
    }
  }
}

// SUPPORT APP
.support-files-wrapper {
  margin: 80px 0 0 70px;
  max-height: calc(100vh - 308px);
  overflow: auto;
  
  .file-item-wrapper {
    display: inline-block;
    margin: 70px 70px 0 0;
    max-width: 200px;
    vertical-align: text-top;
  }

  .file-item-description {
    span {
      font-size: 17px;
      line-height: 22px;
      color: $color-black-text;
      font-weight: 500;
      padding-top: 10px;
      display: inline-block;
    }

    .file-item-type {
      color: $color-gray;
      font-size: 13px;
      padding-top: 0;
      text-transform: uppercase;
    }

    .file-item-duration {
      color: $color-black-text;
      font-size: 13px;
      padding-top: 0;
      display: block;
    }

    .file-item-language {
      color: $color-black-text;
      font-size: 11px;
      padding-top: 0;
      padding-left: 5px;
    }

    .file-item-file-type {
      color: $color-black-text;
      font-size: 11px;
      padding-top: 0;
      display: inline-block;
    }
  }

  img {
    width: 200px;
    height: 150px;
  }
}

.support-footer {
  bottom: 0;
  width: 100%;
  max-width: calc(100% - 325px);
  position: fixed;
  padding: 20px 0;

  a {
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    text-decoration-line: underline;
    color: $color-black-text;
    right: 35px;
    position: absolute;
    bottom: 20px;

    &:focus-visible {
      outline: none;
    }
  }
}

.support-header-filters {
  font-size: 13px;
  line-height: 19px;
  display: flex;
  position: fixed;
  right: 0;
  padding: 23px 35px;
  font-family: "DIN W01 Medium", sans-serif;
  color: $color-black-text;


  [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
    color: $color-black-text;
    line-height: 24px;
  }
}

.status-report {
  margin: 60px 0 0 57px;

  div {
    padding-top: 12px;
    
    &:first-child {
      margin-top: 0;
    }
  }

  .report-main-item {
    font-size: 15px;
    line-height: 19px;
    font-family: "DIN W01 Medium", sans-serif;
  }

  .report-heading {
    font-size: 13px;
    line-height: 16px;
    color: $color-black-text;
    max-width: 260px;
    width: 100%;
    display: inline-block;
    margin-left: 30px;
  }

  .report-item {
    font-size: 15px;
    line-height: 19px;
    font-family: "DIN W01 Medium", sans-serif;
  }

  .report-item-number {
    margin-right: 10px;
  }
}

//ADVERTISE APP
.campaign-menu {
  padding: 30px 0 85px 0;
}

.create-campaign-menu {
  position: absolute;
  left: -160px;
  top: 215px;
  text-align: right;

  span {
    font-size: 13px;
    line-height: 22px;
    display: block;
    color: lighten($color-gray-text, 40%);
  }

  .campaign-active-route {
    color: $color-violet;
  }
}

.create-campaign-wrapper {
  margin-top: 120px;
  padding-left: 70px;

  .displays-counter-create-campaign {
    margin-top: -55px;

    .displays-counter {
      padding-left: 0;
    }
  }

  .products-wrapper {
    .selected-products {
      .products-table {
        padding-left: 0;

        .products-list {
          margin-left: -10px;
        }
      }
    }
  }
  .input-label {
    font-size: 13px;
    line-height: 16px;
    padding-right: 29px;
    text-align: left;
    width: 142px;
    color: $color-gray-text;
  }

  .duration {
    margin-top: -5px;
  }

  .input-app {
    max-width: 343px;
  }

  .schedule-view {
    margin-left: 0;
    margin-top: 0;
    display: inline-block;
    max-width: 500px;
    width: 100%;
    vertical-align: middle;

    .schedule-description {
      display: none;
    }

    .schedule-label {
      max-width: 60px;
      width: 100%;
      display: inline-block;
    }
  }

  .sliders-wrapper {
    display: inline-block;
    max-width: 500px;
    width: 100%;
    vertical-align: middle;
  }

  .create-campaign-footer {
    position: fixed;
    bottom: 0;
    padding: 14px 0;

    &.map-style-display {
      padding: 15px 30px;
      background: rgba(255, 255, 255, 0.95);
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
      border-radius: 60px;
      margin-bottom: 20px;
    }

    button, span {
      outline: none;
      border: none;
      background: transparent;
      padding-right: 30px;
      color: $color-mid-gray;
      padding-left: 0;

      &.active-route {
        color: $color-violet;
      }
    }

    .previous-btn {
      text-decoration: underline;
      text-transform: lowercase;
      font-size: 15px;
      line-height: 24px;
      color: $color-dark-gray;
      margin-right: 25px;

      &.non-visible {
        visibility: hidden;
      }

      &:hover {
        color: $color-violet;
      }
    }

    .next-btn {
      text-transform: uppercase;
      align-items: center;
      height: 28px;
      padding: 0 15px;
      color: $white;
      background: $color-dark-gray;
      border-radius: 25px;
      font-family: "DIN W01 Medium", sans-serif;
      margin-left: 25px;
    }

    a {
      font-size: 15px;
      line-height: 23px;
      border-radius: 25px;
      padding: 3px 15px;
      text-transform: uppercase;
      margin-left: 16px;
      border: 1px solid $color-mid-gray;

      &:focus-visible {
        outline: none;
      }
    }
  }
}

.manage-campaigns-filter {
  text-align: right;

  &.map-style-display {
    padding: 5px 30px;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 60px;
    margin-top: 10px;
    margin-right: 20px;
    width: fit-content;
    position: absolute;
    right: 0;
    z-index: 1;

    .group-type {
      padding: 15px 10px;
    }
  }

  .no-groups-msg {
    padding-right: 10px;
  }
}

.export-pdf-campaign {
  position: fixed;
  right: 0;
  bottom: 13px;
  margin-right: 20px;

  button {
    text-decoration: underline;
    font-size: 15px;
    line-height: 24px;
    background-color: transparent;
    border: none;

    &:focus, &:focus-visible {
      outline: none;
    }
  }
}

.manage-displays {
  padding-left: 5px;

  .products-wrapper .selected-products .products-table {
    padding: 24px 0 0 60px;
  }

  .products__footer-control-bar .products-counter {
    margin-left: 46px;
  }
}

.create-campaign-filter {
  position: absolute;
  right: 0;

  &.map-style-display {
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 60px;
    z-index: 1;

    .group-type {
      padding: 10px;
    }
  }
}

.group-filter {
  display: inline;
  
  div {
    display: inline-block;
  }

  [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
    color: $black;
    font-size: 13px;
    line-height: 24px;
    font-family: "DIN W01 Medium";
  }
}

.campaign-status-filter {

  .custom-radio-button-left {
    [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
      font-family: "DIN W01 Medium";
      line-height: 24px;
      color: $black;
      font-size: 13px;
    }
  }
}

.group-type {
  padding: 23px 35px 0 0;

  .schedule-fields {
    margin-top: 0;

    select {
      max-width: 150px;
      vertical-align: middle;
      font-size: 13px;
      padding-right: 20px;
      background-position: calc(100% - 14px) calc(1em - 1px), calc(100% - 10px) calc(1em - 1px), calc(100% - 24px) 2px;
    }

    span {
      padding-top: 5px;
      font-size: 13px;
      color: $black;
      font-family: "DIN W01 Medium", sans-serif;
    }

    &.create-campaign_groups {
      span {
        font-size: 13px;
        line-height: 32px;
      }

      select {
        padding: 0 37px;
        width: auto;
      } 
    }
  }
}

.create-campaign-upload {
  margin-top: -50px;
  max-height: calc(100vh - 186px);
  overflow: auto;
  
  span {
    font-size: 14px;
    line-height: 20px;
  }

  .upload-description {
    color: $black;
    padding-left: 5px;
  }

  .upload-label {
    color: $color-mid-gray;
    display: block;
  }

  .dropzone__center-info-wrap {
    margin: auto;
    width: 50%;
    text-align: center;

    svg {
      margin-top: 100px;
      width: 73px;
    }

    p {
      margin: 15px 0 0;
      padding: 0;
      font-size: 15px;
      line-height: 19px;
      color: $color-black-text;
      font-family: "DIN W01 Medium", sans-serif;
    }
  
    u {
      margin: 0;
      padding: 0;
      font-size: 12px;
      line-height: 15px;
      color: $color-black-text;
    }
  }

  .dropzone__input{
    width: 350px;
    height: 334px;
    background: #ebebeb;
    border-radius: 6px;
    margin-top: 30px;
    cursor: pointer;
  }

  .input-wrapper {
    margin-top: 100px;
    max-width: 700px;

    .input-links {
      margin-top: 15px;

      .label-title { padding-right: 15px; }

      &:last-child {padding-bottom: 10px;}

      input { 
        margin-right: 30px;
        border: 1px solid $color-gray-border;
        border-radius: 25px;
        
        &:focus,
        &:active {
          outline: none;
          box-shadow: none;
        }
      }

      .time-for-link {
        padding-left: 10px;
      }

      .link-for-display {
        max-width: 200px;
        width: 100%;
        padding-left: 10px;
      }

      .input-links-btns {
        display: inline-block;
        padding-left: 20px;
        vertical-align: top;
      }

      .schedule-fields {
        display: inline;
      }

      .delete-row,.add-btn {
        display: inline-block;
        outline: none;
        background: none;
        border: none;

        svg {
          fill: $color-mid-gray;
        }
      }

      .delete-row {
        svg {
          width: 20px;
          height: 20px;
          &:hover {
            fill: red;
          }
        }
      }

      .add-btn {
        svg {
          width: 21px;
          height: 21px;
          transform: rotate(45deg);
          margin-top: 2px;
          
          &:hover {
            fill: green;
          }
        }
      }
    }
  }
}

.create-campaign-review {
  margin-top: -50px;

  h4 {
    font-size: 18px;
    line-height: 22px;
    color: $color-black-text;
  }

  div {
    margin-top: 35px;
    
    span {
      font-size: 13px;
      line-height: 16px;
      color: $color-dark-gray;
    }

    .label-title {
      max-width: 160px;
      width: 100%;
      display: inline-block;
    }

    .label-description {
      font-size: 15px;
      line-height: 18.6px;
      color: $color-black-text;
    }

    .campaign-value {
      color: $color-violet;
      font-size: 15px;
      font-family: "DIN W01 Medium", sans-serif;
    }
  }

  .review-selected-screens {
    padding: 0;
    margin: 23px 0 0 160px;
    list-style: none;

    li {
      span {
        display: inline-block;
        font-size: 15px;
        line-height: 22px;
        color: $color-black-text;

        &.name,
        &.device-id {
          max-width: 150px;
          width: 100%;
        }

        &.location {
          max-width: 230px;
          width: 100%;
        }
      }
    }
  }
}

.upload-zone__info-area {
  margin-top: 59px;

  .upload-zone__info-title {
    font-size: 13px;
    line-height: 16px;
    color: $color-black-text;
    max-width: 160px;
    width: 100%;
    display: inline-block;
  }

  &.review-page {
    .upload-zone__info-title {
      color: $color-dark-gray;
    }

    .upload-zone__media-list,
    .upload-zone__media {
      margin-top: -1.5px;
    }

    .upload-zone__media-title {
      margin-left: 0 !important;
    }
  }

  .upload-zone__media-list {
    display: inline-block;
    vertical-align: text-top;

    .upload-zone__media {
      .upload-zone__media-title {
        overflow: hidden;
        margin-left: 9px;
        font-size: 15px;
        vertical-align: text-bottom;
        color: $color-violet;
        width: 300px;
        display: inline-block;
        padding-right: 10px;
        font-family: "DIN W01 Medium", sans-serif; 
      }

      .upload-zone__media-remove-wrapper {
        font-size: 15px;
        line-height: 24px;
    
        .upload-zone__media-remove-button{
          background-color: transparent;
          border: none;
          text-decoration: underline;
          color: $color-dark-gray;
          cursor: pointer;
    
          &:hover, &:active, &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

.ordering-arrows {
  width: 18px;
  padding: 2px;
  cursor: pointer;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.centered-text {
  text-align: center;
  margin: 5px 45px;
}


.heating-container {
  max-height: calc(100vh - 222px);
  overflow: auto;
  margin-top: 50px;

  .schedule-view {
    margin-left: 0;
    margin-top: 93px;

    .schedule-description {
      vertical-align: bottom;
    }

    .schedule-row {
      max-width: 600px;
      width: 100%;
      display: inline-flex;
      vertical-align: top;
      margin-top: -3px;

      .schedule-fields {
        margin-top: 10px;
        display: inline-block;
        width: inherit;
      }
    }
  }

  .save-btn {
    padding: 85px 0 0 0;
    position: relative;
    bottom: 0;
    
    .rounded--button {
      background-color: $color-blue;
      border: none;
  
      &:hover {
        background-color: $color-blue-hover;
      }
  
      a {
        text-transform: initial;
      }
    } 
  }
}


.wifi-wrapper {
  .schedule-view {
    margin-left: 30px;
    margin-top: 93px;

    .schedule-description {
      vertical-align: bottom;
    }

    .schedule-row {
      max-width: 600px;
      width: 100%;
      display: inline-flex;
      vertical-align: top;
      margin-top: -3px;

      .schedule-fields {
        margin-top: 10px;
        display: inline-block;
        width: inherit;
      }
    }
  }

  .save-btn {
    padding: 85px 0 0 0;
    margin-left: 31px;
    position: relative;
    bottom: 0;
    
    .rounded--button {
      background-color: $color-blue;
      border: none;
  
      &:hover {
        background-color: $color-blue-hover;
      }
  
      a {
        text-transform: initial;
      }
    } 
  }
}

// Small-screen
@include media-breakpoint-down(sm) {
  .solos-app-content {
    margin-left: 10px;
    max-width: 100%;
    padding-right: 15px;

    .waste-container {
      display: none;
    }

    .waste-edit-container {
     .password-data{
      padding-top: 20px;
     }
     .input-label {
      width: 100px;
     }
     .input-app {
      width:140px
     }
     .select-all {
      font-size: 15px;
     }

     div {
      padding-left: 0 !important;

      .every,.on {
        padding-left: 154px !important;
      }
     }
    }

    .sub-menu {
      position: relative;
      height: auto;
      width: 100%;
      max-width: 100%;
      text-align: left;
      top: 60px !important;
      margin: 10px 0;
      display: block;
      overflow: hidden;

      .menu-item {
        display: inline-block;
        text-align: left;
        padding: 10px 20px;
      }
    }

    .appearance-wrapper {
      margin: 55px;
  
      .theme {
        margin-top: 50px;
      }
  
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .personal-settings, .password {
    width: 100%;
    padding-left: 10px;

    .profile-photo {
      padding-top: 50px;
    }
  }

  .new-user-modal, .edit-users {
    padding-left: 0;

    .user-applications {
      margin: 40px 100px 0 0;
    }
  }

  .users {
    padding: 69px 0 0 10px;
    .users-table {
      .name, .surname,.email, .rights, .user-active {
        max-width: 100%;
      }
    }
  }

  .overview-wrapper {
    max-width: 100%;
    margin: 124px 20px 0;
    width: auto;
  }

  .support-files-wrapper {
    margin: 50px 0 0 70px;
    max-height: calc(100vh - 160px);
  }

  .support-header-filters {
    position: relative;
    padding-top: 100px;
  }

  .support-footer {
    max-width: 100%;
    position: relative;
  }

  .devices-wrapper {
    .devices-header {
      display: none;
    }
  }

  .campaign-menu {
    display: none;
  }

  .create-campaign-filter,.manage-campaigns-filter {
    margin-top: 45px;
    position: initial;
    text-align: left;
  }

  .displays-counter {
    padding-left: 10px !important;
    padding-top: 15px;
  }

  .create-campaign-wrapper {
    padding-left: 10px;
    margin-top: 10px;

    .displays-counter-create-campaign {
      margin-top: 0;
    }

    .data-fields {
      margin-top: 30px;
    }

    .products-wrapper {
      margin-top: 0;

      .products-table {
        padding: 20px 0 0;

        .display-address {
          display: none !important;
        }

        .product-type {
          display: block !important;
          margin-top: -20px;
        }
      }
    }

    .schedule-fields {
      .schedule-label {
        padding-right: 0;
      }

      select {
        padding-right: 10px;
      }
    }

    .create-campaign-footer {
      position: relative;

      .previous-btn, .next-btn {
        margin-right: 0;
        margin-left: 0;
      }
    }

    .displays-data-view {
      position: relative;
      padding: 0;
    }

    .create-campaign-upload {
      margin-top: 100px;

      .dropzone__input {
        width: 100%;
        padding: 10px;
      }
    }

    .create-campaign-review {
      margin-top: 60px;

      div {
        .label-title {
          max-width: 100%;
        }

        .review-selected-screens {
          margin: 0;

          .name, .device-id {
            max-width: 130px;
          }

          .location {
            max-width: 150px;
          }
        }
      }
    }
  }

  .manage-campaigns {
    .manage-campaigns-filter {
      margin-top: 60px;
      text-align: left;
    }

    .products-wrapper {
      margin-top: 0;

      .products-table {
        padding: 0 0 0;

        .products-list {
          margin-top: 0;
          max-height: 100%;

          .checkbox-wrapper {
            .campaign-name {
              padding-left: 0;
              max-width: 100%;
              text-align: center;
            }

            .campaign-date, .campaign-status, .product-group, button {
              max-width: 100%;
              padding-left: 10px;
              display: block;
            }
          }
        }
      }
    }
  }

  .export-pdf-campaign {
    display: none;
  }

  .manage-displays {
    .products-wrapper {
      margin-top: 0;

      .products-table {
        padding: 20px 0 0 !important;

        .products-list {
          margin-top: 0;
          max-height: 100%;

          .checkbox-wrapper {

            span, button, .product-type, .product-number {
              max-width: 100% !important;
              padding-left: 10px;
              display: block;
            }
          }
        }
      }
    }
  }

  .solos-app-content {
    .password {
      .qr-code-wrapper {
        padding: 31px 0 10px 10px;
      }
    }

    .devices-wrapper {
      margin: 55px 0 0 10px;
      .devices-list {
        margin-left:0;

        .date-item,.browser-item, .os-item, .device-item, .item {
          max-width: fit-content;
          padding-right: 10px;
          padding-left: 0;
        }

      }
    }
  }

  .distributor__info-wrap {
    .status-report {
      margin: 30px 0 0 5px;

      .report-heading {
        max-width: 220px;
        margin-left: 5px;
      }
    }

    .export-files {
      display: none;
    }
  }

  .heating-container {
    .schedule-label {
      display: block;
    }
  }
}
