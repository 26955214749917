.toggler {
  margin-top: 50px;
  display: inline-block;
  max-width: 170px;
  width: 100%;
 }

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;

  input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.switch__text {
  font-size: 13px;
  line-height: 16px;
  color: $color-gray-text;
  max-width: 125px;
  width: 100%;
  display: inline-block;
  vertical-align: top;

  .icon-tooltip {
    width: 10px;
    height: 10px;
    vertical-align: top;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  transition: .4s;
  border: 1px solid $color-silver;

  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 3px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
}

.round {
  border-radius: 34px;

  &:before {
    border-radius: 50%;
  }
}

input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
  background-color: $color-gray-text;
}

/*Different color of circle*/
.toggler.gray {
  .round {
    &:before {
    border-radius: 50%;
    background: $color-silver;
    }
  }

  input:checked + .slider:before {
    background-color: $color-gray-text;
  }
}

