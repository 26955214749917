.slider-row {
  label {
    font-size: 15px;
    line-height: 19px;
    color: $color-gray-text;
    margin-right: 20px;
    max-width: 155px;
    width: 100%;
  }

  .slider-wrapper {
    max-width: 250px;
    width: 100%;
    display: inline-flex;

    span {
      display: inline-block;
      font-size: 13px;
      line-height: 16px;
      color: $color-gray-text;
      padding: 0 5px;
    }
    
    input[type="range" i] {
      -webkit-appearance: none;
      appearance: none;
      background: $color-checkbox-gray;
      height: 1px;
      margin-top: 8px;
    }

    input[type='range']::-webkit-slider-thumb {
      width: 14px;
      -webkit-appearance: none;
      height: 14px;
      border-radius: 30px;
      background: $color-gray-text;
    }
  }
}