.setup {
  width: $icons-md;
  height: $icons-md;
  background-image: linear-gradient(225deg, #EDEDED 14.78%, #575756 71.4%);
  border-radius: 50px;
}

.manage {
  width: $icons-md;
  height: $icons-md;
  background-image: linear-gradient(45deg, #8DB61D 14.78%, #36A9E1 71.4%);
  border-radius: 50px;
}

.sensors {
  width: $icons-md;
  height: $icons-md;
  background-image: linear-gradient(225deg, #FBB80F 14.78%, #E5004C 71.4%);
  border-radius: 50px;
}

.waste {
  width: $icons-md;
  height: $icons-md;
  background-image: linear-gradient(45deg, #FFDE00 14.78%, #85AC1C 71.4%);
  border-radius: 50px;
}

.advertise {
  width: $icons-md;
  height: $icons-md;
  background-image: linear-gradient(225deg, #E9434B 23.6%, #E34452 29.7%, #D34663 40.93%, #B8487C 56.02%, #954895 71.4%); 
  border-radius: 50px;
}

.support {
  width: $icons-md;
  height: $icons-md;
  background-image: linear-gradient(45deg, #EA580C 14.78%, #FFD600 71.4%);
  border-radius: 50px;
}

.distributor {
  width: $icons-md;
  height: $icons-md;
  background-image: linear-gradient(351.34deg, #49B3FF -28.66%, #BA00BA 104.67%);
  border-radius: 50px;
}

.light {
  .app-name {
    text-transform: uppercase;
    margin-top: 17px;
    color: $black;
  }
}

.dark,.mid {
  .app-name {
    text-transform: uppercase;
    margin-top: 17px;
    color: $white;
  }
}

// Small-screen
@include media-breakpoint-down(sm) {
  .setup,.manage,.sensors,.manage,.advertise,.support,.distributor,.waste {
    width: $icons-sm;
    height: $icons-sm;
  }
}
