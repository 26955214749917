@font-face {
  font-family: "DIN W01 Regular";
  src:
    url("../fonts/5591097/6ceed230-b2b3-4422-b048-4aa11687430a.woff2") format("woff2"),
    url("../fonts/5591097/80b0143f-6f0d-4dce-aafd-f3c81b85d177.woff") format("woff");
}

@font-face {
  font-family: "DIN W01 Medium";
  src:
    url("../fonts/5591111/398e3e8c-3bf0-4af1-9791-f426a7992711.woff2") format("woff2"),
    url("../fonts/5591111/4ba8e512-e6fb-494f-afd3-a7b68b2e5efb.woff") format("woff");
}

body {
  font-family: "DIN W01 Regular", sans-serif;
  margin: 0;
  padding: 0 !important;
  background-color: $white;
}

main {
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: inherit;
}

ul, ol {
  padding-left: 15px;
  margin-bottom: 0;
}

a {
  color: $black;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: $black;
  }
}

img {
  width: 100%;
}

.clear { 
  clear: both; 
} 

.right { 
  float: right; 
} 

.left { 
    float: left; 
}

.container {
  margin: 0 auto;
  padding: 0;
}

.hidden {
  display: none !important;
}

.page__wrap {
  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }
  
  overflow: hidden;
  min-height: 100vh;
}

// loading spinner
.load {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  z-index: 99999;

  &.loaded {
    display: none !important;
  }

  .load__icon-wrap {
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    transform: rotate(3deg);
    transform: rotate(0.3rad);
    transform: rotate(3grad); 
    transform: rotate(.03turn);

    svg {
      width: 35px;
      height: 35px;
    }
  }
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
