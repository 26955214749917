.homepage-wrapper {
  height: 100vh;
  background: $white;
  background-size: cover;

  &.dark {
    background: $black;
    background-size: cover;
  }

  &.mid {
    background: $color-mid-gray-theme;
    background-size: cover;
  }

  .icons-container {
    max-width: 588px;
    width: 100%;
    max-height: 322px;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .application-wrapper {
      justify-content: space-between;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      width: 100%;
      height: 322px;
    }
  }
}

// Small-screen
@include media-breakpoint-only(xs) {
  .homepage-wrapper {
    .icons-container {
      margin-top: 50px;
      top: auto;
      left: auto;
      position: relative;
      transform: none;
    }

    .footer {
      position: sticky;
    }
  }
}