.header-container {
  &.dark, &.mid {
    color: $white;
  }

  &.light {
    color: $black;
  }

  display: flex;
  justify-content: space-between;

  .header {
    text-align: left;
    display: flex;
    padding-left: 20px;
    padding-top: 37px;
  
    .logo {
      width: 200px;
      height: 25px;
    }
  
    .brand {
      font-size: 18px;
      line-height: 24px;
      margin-left: -32px;
    }
  }

  .header__profile-menu-wrap {
    max-width: 172px;
    width: 100%;
    position: absolute;
    margin-top: 55px;
    margin-left: 10px;
    background: $white;
    opacity: 0.9;
    box-shadow: 0px 24px 50px rgba(0, 0, 0, 0.25);
    z-index: 1;

    .header__profile-menu {
      background: $white;
      opacity: 0.9;
      padding: 10px 23px;
    }

    .header__profile-menu-pointer {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 6px solid $white;
      position: absolute;
      opacity: 0.9;
      left: 12px;
      top: -6px;
    }
  }

  .header__profile-info {
    border: none;
    outline: 0 !important;
    background-color: transparent;
    padding-right: 0;
    margin-top: -7px;

    .header__profile-avatar {
      width: 38px;
      height: 38px;
      border-radius: 30px;
      /*
      &.dark {
        border: 2px solid $white;
      }

      &.light {
        border: 2px solid $black;
      }*/
    }

    .header__profile-name {
      margin-left: 15px;
      vertical-align: middle;

      &.dark, &.mid {
        color: $white;
      }

      &.light {
        color: $black;
      }
    }
  }

  .topbar__link-title {
    padding-top: 5px;
    font-size: 13px;
    line-height: 39px;
    display: block;
    color: $color-gray;
  }

  .header__control-panel {
    display: flex;
    float: right;
    margin-right: 75px;
    margin-top: 30px;

    .header__profile-company {
      margin-top: 2px;
      margin-left: 15px;
    }
    
    .add-product__icon {
      width: 35px;
      height: 35px;
      font-size: 15px;
      margin-left: 30px;
      margin-top: 6px;
      cursor: pointer;
    }

    .topbar__icon-wrapper {
      margin-left: 33px;
      display: flex;

      .notification-icon-container {
        height: 43px;
        display: flex;
      
        .notification-number {
          margin-left: -12px;
          width: 28px;
          height: 16px;
          border-radius: 30px;
          background-color: $color-notifications-indicator;
          vertical-align: top;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          text-align: center;
          color: $white;
        }
      
        .hamburger-menu {
          margin-top: 5px;
          width: 31px;
          height: 31px;
          border: 2px solid ;
          border-radius: 20px;

          hr:not([size]) {
            height: 0;
          }
        
          .line {
            border: 1px solid ;
            width: 15px;
            margin: 4px;
            margin-left: 7px;
            opacity: 1;
          }
        
          .line-top {
            margin-top: 9px;
          }
        
          .line-shorter {
            width: 12px;
          }
        }
      }
    }
  }
}

.hamburger-menu.dark, .hamburger-menu.mid {
  &:focus {
    outline: none;
  } 
      
  &:hover,&:focus-visible {
    color: $white;
  }

  .line {
    background: $white;
    border-color: $white;
  }
}

.hamburger-menu.light {
  &:focus {
    outline: none;
  } 
      
  &:hover, &:focus-visible {
    color: $black;
  }

  .line {
    background: $black;
  }
}

// Small-screen
@include media-breakpoint-down(sm) {
  .header-container {
    display: block;

    .header {
      display: block;
      padding-left: 0;
    }

    .header__profile-company {
      display: none;
    }

    .header__control-panel {
      float: none;
      margin-right: 0;
      text-align: center;
      display: block;

      .header__profile-info {
        display: block;
      }

      .header__profile-menu-wrap {
        margin-top: 5px;
      }

      .topbar__icon-wrapper {
        display: inline-flex;
        margin-left: 0;
      }
    }
  }
}
