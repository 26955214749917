.custom__modal-container {
  max-width: 655px;
  width: 100%;
  margin-top: 199px;
  opacity: 0.9;

  
  &.product-details-modal {
    .modal-content {
      height: 411px;
    }

    .custom__modal-header {
  
      .product-id {
        margin: 23px 0 0 51px;
        display: inline-block;

        span {
          font-size: 15px;
          line-height: 24px;
        }
      }
    }

    .custom__modal-body {
      overflow: auto;
      max-height: 300px;
      max-width: 585px;
      margin: 22px 0 0 51px;
      width: 100%;
      padding-right: 20px;
    }
  }

  &.selected-products-modal {
    .modal-content {
      height: 411px;
    }

    .custom__modal-header {
      width: 100%;
      position: absolute;
      top: 0;
      display: flex;
      justify-content: space-between;
      z-index: 1;
      pointer-events: all;
    }
  }

  .custom__modal-header {
    margin: 0;
    z-index: 1;

    .header-controls {
      float: left;
      padding: 22px 0 0 46px;

      .custom-radio-button-left {
        display: inline-block;
      }
    }

    .notifications-modal {
      margin: 23px 0 0 51px;
      color: $color-black-text;
      display: inline-block;
      font-size: 15px;
      line-height: 24px;
    }

    .close-btn {
      float: right;
      padding: 0;
      margin: 10px 10px 0 0;
      outline: none;
      border: none;
      background: transparent;
    }
  }

  .custom__modal-body {
    max-width: 533px;
    min-height: 220px;
    margin: 22px 83px 0 51px;

    &.campaign-modal {
      .custom__modal-description {
        &:first-child {
          padding-top: 0;
        }

        h2 {
          text-align: center;
          margin-top: 80px;
        }
      }
    }

    .custom__modal-title {
      font-weight: 500;
      font-size: 24px;
      line-height: 22px;
      color: $color-modal-title;
      margin-top: 22px;
      margin-bottom: 46px;

      &.system-warning {
        color: $color-modal-title-warning;
      }
    }

    .custom__modal-description {
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
    }
  }

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    margin-left: 51px;
    margin-top: 140px;
    margin-bottom: 15px;
    padding-right: 19px;

    .custom__modal-container-button {
      border: none;
      outline: none;
      background-color: $white;
    }
    
    .scroll-buttons {
      text-align: right;

      .next {
        margin-left: 32px;
      }
      
      .scroll-icon {
        width: 35px;
        height: 30px;
        margin-top: -4px;
      }
    }
  }

  .group-picker-form {
    .group-input {
      padding-top: 15px;
      max-height: 250px;

      .edit-group-description {
        padding-top: 5px;
      }
    }

    .action-btns {
      padding: 15px 0;
    }
  }

  .select-groups {
    height: 360px;
  }

  .activation-form {
    .input-label {
      font-size: 13px;
      line-height: 19px;
      padding-right: 41px;
      color: $color-black-text;
      vertical-align: text-top;
      text-align: right;
      width: 90px;
    }
  
    .input-app {
      max-width: 298px;
      height: 29px;
      width: 100%;
      border: 1px solid $color-silver;
      box-sizing: border-box;
      border-radius: 60px;
      padding: 3px 12px;
      font-size: 18px;
      line-height: 32px;
      display: inline-block;
  
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .input-error {
      font-size: 12px;
      line-height: 13px;
      color: $color-modal-title-warning;
      margin-bottom: -5px;
      display: block;
      margin-top: 10px;
    }
  }

  .products-modal-table {
    max-height: 255px;
    overflow: auto;
    margin: 80px 0 0 36px;

    ul {
      list-style-type: none;
      padding: 0;
      margin-right: 35px;

      li {
        padding-bottom: 5px;

        span {
          font-size: 15px;
          line-height: 22px;
          color: $color-black-text;
          display: inline-flex;
          white-space: nowrap;
          overflow: hidden;
        }

        .product-type {
          width: 25%;
          margin-left: 40px;
        }

        .product-number {
          width: 20%;
          margin: 0 10px;
        }

        .product-address {
          width: calc(55% - 80px);
          margin: 0 10px;
        }
      }

      .checkbox-wrapper {
        &.orange {
          [type="checkbox"]:not(:checked) + label:after,
          [type="checkbox"]:checked + label:after {
            content:url(
              'data:image/svg+xml;utf-8,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" fill="rgb(255, 95, 31)" stroke="white" stroke-width="2"></circle></svg>'
            );
          }
        }

        &.blue {
          [type="checkbox"]:not(:checked) + label:after,
          [type="checkbox"]:checked + label:after {
            content:url(
              'data:image/svg+xml;utf-8,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" fill="rgb(2,195,255)" stroke="white" stroke-width="2"></circle></svg>'
            );
          }
        }

        &.purple {
          [type="checkbox"]:not(:checked) + label:after,
          [type="checkbox"]:checked + label:after {
            content:url(
              'data:image/svg+xml;utf-8,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" fill="rgb(117, 110, 229)" stroke="white" stroke-width="2"></circle></svg>'
            );
          }
        }

        &.green {
          [type="checkbox"]:not(:checked) + label:after,
          [type="checkbox"]:checked + label:after {
            content:url(
              'data:image/svg+xml;utf-8,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" fill="rgb(124,252,0)" stroke="white" stroke-width="2"></circle></svg>'
            );
          }
        }
      }
    }
  }

  .products-map {
    display: block;
    width: 100%;
    height: 411px;
  
    .gm-style-mtc, 
    .gm-svpc, 
    .gm-style-cc {
      display: none;
    }

    .gmnoprint {
      border-radius: 35px !important;

      div {
        border-radius: 25px !important;
        margin-top: 15px;

        button {
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .product__modal-footer {
    max-width: 605px;
    width: 100%;
    margin: 20px 20px 20px 46px;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    
    .orange {           
      color: $color-orange;
    }

    .blue {           
      color: $color-blue;
    }

    .purple {           
      color: $color-purple;
    }

    .green {           
      color: $color-green;
    }

    .products-counter {
      font-family: "DIN W01 Medium", sans-serif; 
      float: left;

      &.map-style-display {
        padding: 15px 30px;
        background: rgba(255, 255, 255, 0.95);
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
        border-radius: 60px;
      }
    }

    .products__view-switcher {
      text-align: right;
      float: right;
      margin-right: 25px;
      display: flex;

      &.map-style-display {
        padding: 18px 27px 14px 0;
        background: rgba(255, 255, 255, 0.95);
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
        border-radius: 60px;
      }
    }
  }

  //Success Response modal
  .modal__body {
    text-align: center;
  }

  .modal__btn {
    text-align: center;
    padding: 10px;

    .rounded--button {
      height: 28px;
    }
  }
}

.modal-open {
  overflow: hidden;
}

.cookies-text {

  h4 {
    margin-top: 30px;
  }

  margin: 20px;
  text-align: justify;

  ul {
    margin: 10px 0;
    padding-left: 20px;
    font-size: 18px;
    line-height: 32px;
    color: $color-gray;
  }
}

.cookies-modal {
  margin-bottom: 60px;
}

//Edit users modal, Add new user modal
.edit-users, .new-user-modal {
  .setup-modal-body {
    margin-left: 80px;

    .new-user-form {
      &.permissions {
        max-height: 280px;
        overflow: auto;
      }

      padding-top: 10px;
      margin-right: 45px;
      max-height: 300px;
      overflow: auto;
    }

    .save-btn {
      text-align: right;
      margin-right: 110px;
      padding: 20px 0;
    }
  }

  h3 {
    font-size: 15px;
  }

  .profile-photo {  
    padding-top: 0 !important;
  }

  .users-modal-radio-btn {
    margin: 30px 0 0 0;
    display: flex;
    font-size: 13px;
    font-weight: 500;

    div {
      [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
        padding-left: 0; 
        color: $color-black-text;
      }
    }

    .user-applications {
      margin: 0;
    }
  }

  .user-applications {
    margin: 40px 278px 0 0;
    text-align: right;

    .app-rights {
      margin-top: 20px;

      span {
        font-size: 13px;
        line-height: 19px;
        color: $color-black-text;
      }

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .add-user-btn {
    margin: 33px 0px 36px 410px;
  }

  .action-btns {
    text-align: right;

    div {
      display: inline-block;
      margin-right: 16px;
    }

    .save-btn, .cancel-btn {
      padding: 32px 0 10px 0;
    }

    .cancel-btn {
      display: inline-block;
      padding-right: 10px;
    
      .rounded--button {
        height: 28px;
        background-color: $white;
        
        a {
          color: $color-dark-gray;
        }
      }
    }
  }
}

.confirm-modal {
  .modal__body {
    text-align: left;
    padding: 0 20px;

    h3 {
      border-bottom: 1px solid $color-silver;
    }

    &.terms-body {
      padding-top: 30px;
    }
  }

  .modal__btn {
    text-align: right;

    button {
      margin-right: 10px;
    }
  }
}

.aqi-modal {
  max-width: 655px !important;
  width: 100%;
  
  .intro-text {
    padding: 25px 90px 32px 40px;
  }

  p {
    font-size: 13px;
    color: $black;
    line-height: 16px;
    font-family: "DIN W01 Regular";
  }

  .modal__body {
    padding: 0 55px 32px 40px;
    max-height: 230px;
    overflow: auto;

    .index-description {
      text-align: left;
      padding-top: 30px;

      &:first-child {
        padding-top: 0;
      }

      .text-label {
        vertical-align: super;
        padding-left: 6px;
        font-size: 15px;
        line-height: 22px;
        font-family: "DIN W01 Medium", sans-serif;
      }

      .text-description {
        padding-left: 25px;
        margin: 0;
      }
    }
  }
}

.circle-color {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 25px;

  &.good {background-color: #50F0E6;}

  &.fair {background-color: #50CCAA;}

  &.moderate {background-color: #F0E641;}

  &.poor {background-color: #FF5050;}

  &.very-poor {background-color: #960032;}

  &.extremely-poor {background-color: #7D2181;}
}

.display-modal {
  max-width: 655px !important;
  width: 100%;

  .modal-content {
    height: 100%;
    max-height: 411px;
  }

  .no-groups-report {
    padding-left: 61px;

    .modal-btn {
      background: transparent;
      text-decoration: underline;
      outline: none;
      border: none;
      font-size: 15px;
      line-height: 24px;
      color: $color-dark-gray;
      padding: 0 10px 30px 0;
    }
  }

  .status {
    display: inline-block;
    padding-left: 61px;
    padding-top: 20px;
    width: 500px;

    span {
      font-size: 13px;
      line-height: 16px;
      color: $color-black-text;
    }

    .toggler {
      margin-top: 0;
      max-width: 220px;

      .switch__text {
        max-width: 160px;
      }
    }

    .response-msg {
      padding: 30px;
      color: $color-dark-gray;
    }
  }

  .group-input {
    padding-top: 50px;

    .group-name {
      padding-top: 10px;
    }

    .group-label {
      font-size: 15px;
      line-height: 24px;
    }
  }

  .export-btn {
    left: 56px;
    bottom: 12px;
    position: absolute;
  }

  .gps-link {
    padding-right: 0 !important;
    margin-left: -18px;
  }

  .edit-link {
    font-size: 15px;
    line-height: 24px;
    cursor: pointer;
    padding-right: 30px;

    .edit-modal-button {
      background-color: transparent;
      border: none;
      text-decoration: underline;
      color: $color-dark-gray;
      cursor: pointer;
      text-transform: lowercase;

      &:hover, &:active, &:focus {
        outline: none;
      }
    }
  }

  .uploader-input {
    display: none;
  }

  .custom__modal-body {
    padding-left: 10px;
    max-height: 350px;
    overflow: auto;
    padding-right: 50px;
    margin: 0 0 0 51px;

    .custom__modal-description-links {
      padding-top: 0 !important;
      padding-left: 152px;
      margin: 0;
    }

    .add-video-btn {
      padding-left: 159px;
    }

    .custom__modal-description {
      padding-top: 30px;

      .duration-text {
        max-width: 250px !important;
      }
      
      .info-no-color {
        font-size: 15px;
        line-height: 19px;
        color: $color-gray-text;
        max-width: 195px;
        width: 100%;
        display: inline-block;
        padding-right: 10px;
        font-family: "DIN W01 Medium", sans-serif; 
      }

      .title-loop-modal {
        vertical-align: text-top;
      }

      .title {
        font-size: 13px;
        line-height: 16px;
        color: $color-black-text;
        max-width: 160px;
        width: 100%;
        display: inline-block;
      }

      .info, .info-input {
        overflow: hidden;
        font-size: 15px;
        line-height: 21px;
        max-width: 195px;
        width: 100%;
        display: inline-block;
        padding-right: 10px;
        font-family: "DIN W01 Medium", sans-serif;
        vertical-align: text-top; 

        &.padding-left {
          padding-left: 18px;
        }

        &.violet { color: $color-violet;}
        &.blue { color: $color-blue;}
        &.orange { color: $color-orange;}
        &.purple { color: $color-purple;}
        &.green { color: $color-green;}
      }

      .info-screen-name {
        font-size: 15px;
        line-height: 22px;
        color: $color-black-text;
        max-width: 195px;
        width: 100%;
        display: inline-block;
        padding-right: 10px;
      }

      .info-input {
        border: none;
        background: transparent;

        &:focus, &:focus-visible {
          border-bottom: 0.5px solid $color-dark-gray;
          outline: none;
        }
      }

      .group-type {
        display: inline-block;
        padding-top: 0;

        .schedule-fields {
          margin-top: 0;

          select {
            font-size: 13px;
            padding-right: 20px;
            background-position: calc(100% - 14px) calc(1em - 1px), calc(100% - 10px) calc(1em - 1px), calc(100% - 24px) 2px;
          }
        }
      }

      .input-app {
        max-width: 343px;
        height: 29px;
        width: 100%;
        border: 1px solid $color-silver;
        box-sizing: border-box;
        border-radius: 60px;
        padding: 3px 12px;
        font-size: 18px;
        line-height: 32px;
        display: inline-block;
    
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  .action-btns {
    text-align: right;

    div {
      display: inline-block;
      margin-right: 16px;
    }

    .save-btn, .cancel-btn {
      padding: 32px 0 10px 0;
    }

    .cancel-btn {
      display: inline-block;
      padding-right: 10px;
    
      .rounded--button {
        height: 28px;
        background-color: $white;
        
        a {
          color: $color-dark-gray;
        }
      }
    }
  }
}

.modal-error {
  margin: 5px 0 0 0;
  font-size: 12px;
  line-height: 12px;
  color: $color-violet;
  display: block;
  margin-left: 160px;
}

// Small-screen
@include media-breakpoint-down(sm) {

  .custom__modal-container {
    .custom__modal-header {
      .header-controls {
        padding: 17px 0 0 43px;
      }

      .status {
        padding-left: 10px;
        width: 195px;
      }
    }

    .buttons-wrapper {
      font-size: 13px;
    }

    .product__modal-footer {
      margin: 0;

      .products__view-switcher {
        text-align: left;
        display: block;
        padding-top: 18px;
      }

      .products-counter .map-style-display {
        padding: 15px 2px;
      }
    }

    .products-modal-table {
      margin: 80px 0 0 5px;
    }
  }

  .new-user-modal {
    .setup-modal-body {
      margin-left: 20px;
    }
  }

  .display-modal {
    max-width: calc(100% - 20px) !important;

    .modal-content {
      max-height: 100%;
    }

    .campaign-modal {
      margin: 0;
      padding-right: 10px;

      .custom__modal-body {
        .custom__modal-description {

          .title {
            display: block;
          }

          .info {
            max-width: calc(100% - 20px);
            display: contents;
          }
        }
      }

      .custom__modal-description-links {
        padding-left: 10px !important;
      }
    }

    .export-btn {
      left: 0;
      bottom: 0;
      position: relative;
    }

    .display-edit-modal {
      margin: 0;

      .edit-link {
        display: contents;
      }

      .add-video-btn {
        padding-left: 20px;
      }
    }

    .group-input {
      .custom__modal-description {
        .info-input, .info, .info-screen-name {
          max-width: 50%;
        }
      }

      .add-video-btn {
        padding-left: 10px;
      }
    }
  }

  .aqi-modal {
    max-width: calc(100% - 20px) !important;

    .intro-text {
      padding: 25px 50px 30px 20px;
    }

    .modal__body {
      padding: 0 50px 32px 20px;
      max-height: 100%;
      overflow: visible;
    }
  }
}