p, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
}

p {
  margin: 10px 0;
  font-size: 18px;
  line-height: 32px;
  color: $color-gray;
  font-style: normal;
  font-weight: normal;

  &:first-child {
    margin: 0;
  }
}

span {
  font-size: 18px;
  line-height: 32px;
}

h1 {
  font-size: 70px;
  line-height: 85px;
  font-style: normal;
  font-weight: normal;
  font-weight: 400;

  &.subhead {
    font-size: 30px;
    color: $color-gray;
    line-height: 36px;
  }
}

h2 {
  font-size: 30px;
  line-height: 36px;

  &.subhead {
    font-size: 24px;
    color: $color-gray;
    line-height: 32px;
  }
}

h3 {
  font-size: 24px;
  line-height: 32px;

  &.subhead {
    font-size: 18px;
    color: $color-gray;
    line-height: 24px;
  }
}

h4 {
  font-size: 18px;
  line-height: 24px;

  &.subhead {
    font-size: 12px;
    color: $color-gray;
    line-height: 16px;

    img {
      width: 15px;
      height: 15px;
    }
  }
}

h5 {
  font-size: 14px;
  line-height: 18px;

  &.subhead {
    font-size: 10px;
    color: $color-gray;
    line-height: 12px;
  }
}

h6 {
  font-size: 12px;
  line-height: 16px;

  &.subhead {
    font-size: 8px;
    color: $color-gray;
    line-height: 10px;
  }
}

blockquote {
  font-size: 13px;
  font-style: italic;
  margin-bottom: 0;
}

.highlight {
  background-color: $color-light-gray;
  color: #ffffff;
}

::selection {
  color: #ffffff;
  background: $color-selection;
}

.orange {
  ::selection {
    background: $color-orange;
  }
}

.blue {
  ::selection {
    background: $color-blue;
  }
}

.green {
  ::selection {
    background: $color-green;
  }
}

.violet {
  ::selection {
    background: $color-violet;
  }
}

.yellow {
  ::selection {
    background: $color-yellow;
  }
}

.orange {
  ::selection {
    background: $color-orange;
  }
}

.purple {
  ::selection {
    background-color: $color-purple;
  }
}

.dark,.mid {
  color: white;
}

.light {
  color: black;
}

// //custom-scrollbar
// ::-webkit-scrollbar {
//   width: 10px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px $color-light-gray; 
//   border-radius: 10px;
// }
 
// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: $color-mid-gray; 
//   border-radius: 10px;
  
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: $color-mid-gray; 
// }

::-webkit-scrollbar-track {
  /* border-right: 1px solid white;
  border-left: 3px solid white;
  border-bottom: none; */
  /* border-top: none; */
  /* background: gray; */
  // width: 20px;

  background-image: url('../icons/scrollbar-line.svg');
  background-position: center;
  background-repeat-x: no-repeat;
  margin: 50px 0px;
}

::-webkit-scrollbar-track:horizontal{background-repeat:repeat-x}
::-webkit-scrollbar-track:vertical{background-repeat:repeat-y}

::-webkit-scrollbar {
  width: 40px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-image: url('../icons/scrollbar-thumb.svg');
  background-position: center center;
  background-repeat: no-repeat, no-repeat;
}

//Radio-btn
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -99999999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-right: 28px;
  padding-left: 30px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  right: 0;
  top: 3px;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: $white;
}

.custom-radio-button-left {
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: '';
    left: 0;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    left: 3px;
  }
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 14px;
  height: 14px;
  background: $color-gray-text;
  position: absolute;
  top: 6px;
  right: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
opacity: 0;
-webkit-transform: scale(0);
transform: scale(0);
}

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}


// Small-screen
@include media-breakpoint-down(sm) {
  h1 {
    font-size: 30px;
    line-height: 50px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
