.schedule-fields {
  margin-top: 10px;

  span {
    font-weight: 300;
    font-size: 15px;
    line-height: 19px;
    color: $color-gray-text;
    padding-right: 20px;
    vertical-align: text-top;
  }

  select {
    font-size: 18px;
    padding-right: 20px;
    max-width: 70px;
    width: 100%;
    height: 28px;
    border-radius: 60px;
    border-color: $color-gray-border;
    margin-right: 10px;
    text-align-last: center;
    background-image: linear-gradient(45deg, transparent 50%, black 0%), linear-gradient(135deg, black 58%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 14px) calc(1em - 4px), calc(100% - 10px) calc(1em - 4px), calc(100% - 24px) 2px;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    
    &:focus-visible {
      outline: none;
    }
  }

  select::-ms-expand {
    display: none;
  }
}

.ambient-light {
  margin-top: 143px;
  max-height: calc(100vh - 222px);
  overflow: auto;

  .schedule-view {
    margin-left: 59px;

    &:first-child {
      margin-top: 0;
    }

    .schedule-description {
      vertical-align: bottom;
    }

    .schedule-row {
      max-width: 600px;
      width: 100%;
      display: inline-flex;
      vertical-align: top;
      margin-top: -3px;

      .schedule-fields {
        margin-top: 10px;
        display: inline-block;
        width: inherit;
      }
    }
  }

  .save-btn {
    padding: 85px 0 0 0;
    margin-left: 59px;
    
    .rounded--button {
      background-color: $color-blue;
      border: none;
  
      &:hover {
        background-color: $color-blue-hover;
      }
  
      a {
        text-transform: initial;
      }
    } 
  }
}

.schedule-view {
  margin-left: 65px;
  margin-top: 80px;
  
  .schedule-description, .schedule-row {
    display: inline-block;
  }

  .schedule-description {
    max-width: 300px;
    width: 100%;
    font-weight: 500;
    vertical-align: super;

    h3 {
      font-size: 18px;
      line-height: 22px;
    }

    h4.subhead {
      font-size: 15px;
      line-height: 22px;
    }
  }

  .schedule-row {
    max-width: 500px;
    width: 100%;

    &.field-center {
      vertical-align: top;

      .schedule-fields {
        margin-top: 5px;

        span {
          vertical-align: text-top;
        }
      }
    }
  }

  .save-btn {
    position: fixed;
    bottom: 35px;
    padding-top: 0;
    
    .rounded--button {
      background-color: $color-blue;
      border: none;
  
      &:hover {
        background-color: $color-blue-hover;
      }
  
      a {
        text-transform: initial;
      }
    } 
  }
}

// Small-screen
@include media-breakpoint-down(sm) {
  .schedule-view {
    margin-left: 0;

    .save-btn {
      display: inline-block;
      position: relative;
      margin-top: 50px;
    }
  }
}

.waste-fields {
  margin-top: 10px;

  span {
    font-weight: 300;
    font-size: 15px;
    line-height: 19px;
    color: $color-gray-text;
    padding-right: 20px;
    vertical-align: text-top;
  }

  select {
    font-size: 18px;
    padding-right: 20px;
    max-width: 150px;
    width: 100%;
    height: 28px;
    border-radius: 60px;
    border-color: $color-gray-border;
    margin-right: 10px;
    text-align-last: center;
    background-image: linear-gradient(45deg, transparent 50%, black 0%), linear-gradient(135deg, black 58%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 14px) calc(1em - 4px), calc(100% - 10px) calc(1em - 4px), calc(100% - 24px) 2px;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    
    &:focus-visible {
      outline: none;
    }
  }

  select::-ms-expand {
    display: none;
  }
}
