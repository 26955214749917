.tooltip-overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:transparent;
  z-index: -2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.tutorial-btn {
  position: absolute;
  top: 120px;
  left: 60px;
  display: block;
  background-color: $black;
  opacity: 0.8;
  color: $white;
  border-radius: 25px;
  height: 40px;
  border: none;
  padding: 5px 20px;

  &:focus, &:active {
    outline: none;
  }

  -webkit-animation: glowing 3000ms infinite;
  -moz-animation: glowing 3000ms infinite;
  -o-animation: glowing 3000ms infinite;
  animation: glowing 3000ms infinite;

  @-webkit-keyframes glowing {
    0% { background-color: $color-modal-title; -webkit-box-shadow: 0 0 3px $color-modal-title; }
    50% { background-color: black; -webkit-box-shadow: 0 0 40px black; }
    100% { background-color: $color-modal-title; -webkit-box-shadow: 0 0 3px $color-modal-title; }
  }

  @-moz-keyframes glowing {
    0% { background-color: $color-modal-title; -moz-box-shadow: 0 0 3px $color-modal-title; }
    50% { background-color: black; -moz-box-shadow: 0 0 40px black; }
    100% { background-color: $color-modal-title; -moz-box-shadow: 0 0 3px $color-modal-title; }
  }

  @-o-keyframes glowing {
    0% { background-color: $color-modal-title; box-shadow: 0 0 3px $color-modal-title; }
    50% { background-color: black; box-shadow: 0 0 40px black; }
    100% { background-color: $color-modal-title; box-shadow: 0 0 3px $color-modal-title; }
  }

  @keyframes glowing {
    0% { background-color: $color-modal-title; box-shadow: 0 0 3px $color-modal-title; }
    50% { background-color: black; box-shadow: 0 0 40px black; }
    100% { background-color: $color-modal-title; box-shadow: 0 0 3px $color-modal-title; }
  }
}

.tooltip-padding {
  width: 50px;
}

.tooltip-helper-backdrop {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  pointer-events: none;
}

.tooltip-helper-active-description {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  pointer-events: all;

  position: absolute;
  font-size: 15px;
  border-radius: 5px;
  max-width: 500px;
  z-index: 9998;
  transition-property: transform;
  will-change: transform;
  transform: translateX(0px) translateY(0px);
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  box-sizing: border-box;
}

.tooltip-helper-active-description h3 {
  font-size: 20px;
  color: black;
}

.tooltip-helper-active-description p {
  color: #8F9497;
  overflow-y: auto;
  height: 70%;
  padding: 10px 25px 20px 0px;
  margin: 25px !important;
}

.tooltip-helper-footer {
  float: right;
  padding: 10px;
}

.tooltip-helper-footer > div {
  display: flex;
  bottom: 5px;
  right: 10px;
  position: absolute;
}

.tooltip-helper-active {
  position: absolute;
  transition-duration: 150ms;
  transition-property: transform;
  will-change: transform;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.tooltip-helper-active-element {
  overflow: hidden;
  position: relative;
}

.tooltip-helper-next-sequence,
.tooltip-helper-prev-sequence {
  background-color: black;
  border: none;
  outline: none;
  padding: 3px 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  border-radius: 25px;
  font-family: "DIN W01 Regular", sans-serif;
}

.tooltip-helper-end-sequence {
  background-color: $white;
  position: absolute;
  top: 0;
  right: 5px;
  border: none;
  outline: none;
  color: transparent; //hide quit text
  display: flex;
  height: 35px;
  width: 35px;
  background-image: url('../icons/closeIcon.svg');
}

.tooltip-helper-end-sequence:focus,
.tooltip-helper-next-sequence:focus,
.tooltip-helper-prev-sequence:focus {
  outline: none;
}

.tooltip-disabled-btn {
  background-color: rgb(92, 92, 92) !important;
  user-select: none !important;
  color: rgba(255, 255, 255, 0.671);
}

.tooltip-helper-arrow {
  position: absolute;
  width: 0; 
  height: 0; 
  z-index: 9997;  
  transition-property: transform;
  will-change: transform;
  transform: translate3d(0px, 0px, 0px);
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.tooltip-helper-arrow-up {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid white;
  opacity: 0.7;
}

.tooltip-helper-arrow-down {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid white;
  opacity: 0.7;
}

.tooltip-helper-arrow-left {
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid white;
  opacity: 0.7;
}

.tooltip-helper-arrow-right {
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid white;
  opacity: 0.7;
}

// Small-screen
@include media-breakpoint-down(sm) {
  .tutorial-btn {
    position: sticky;
    margin-top: 10px;
  }
}