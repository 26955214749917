$themes: (
  light: (
    colorBackground: white,
    colorBackgroundBody: #f2f4f7,
    inboxButtonBackground: #f2f4f7,
    inboxButtonBackgroundHover: darken(#f2f4f7, 5%),
    colorHeaderDataPicker:#6db0ff,
    colorText: #646777,
    colorTextAdditional: #646777,
    logoImg: url(../shared/img/logo/logo-light.png),
    colorHover: #fafbfe,
    colorFolderHover: #f0eeee,
    colorBorder: #eff1f5,
    colorIcon: #dddddd,
    imgInvert: invert(0%),
    colorFieldsBorder: #f2f4f7,
    colorBubble: rgba(242, 244, 247, 0.65),
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    colorScrollbar: #B4BFD0,
    colorFitness: #646777,
    colorEmoji: #232329,
    sidebarColor: aliceblue,
  ),
  dark: (
    colorBackground: #232329,
    colorBackgroundBody: #2a2a31,
    inboxButtonBackground: #2a2a31,
    inboxButtonBackgroundHover: lighten(#2a2a31, 5%),
    colorHeaderDataPicker:#063263,
    colorText: #dddddd,
    colorTextAdditional: #999999,
    logoImg: url(../shared/img/logo/logo-dark.png),
    colorHover: #38373f,
    colorFolderHover: #ffffff1A,
    colorBorder: #333246,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff,
    colorEmoji: #ffffff,
    sidebarColor: #232329,
  )
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

// Include COLORS
$color-light-gray: #eef0f1;
$color-mid-gray: #bdc4c9;
$color-dark-gray: #747a7d;
$color-gray: #8F9497;
$color-checkbox-gray: #D6D6D6;
$color-gray-border: #C4C4C4;
$color-gray-text: #525252;
$color-modal-title: #404040;
$color-black-text: #202020;
$color-light-blue: #DDE0E2;
$color-notifications-indicator: #FF3D00;
$color-modal-title-warning: #FF2E00;
$color-silver: #C0C0C0;
$color-mid-gray-theme: #636363;

// Solos apps colors
$color-black: #4A4949;
$color-orange: #FF5901;
$color-blue: #02C3FF;
$color-green: #5BCE01;
$color-violet: #e34a5e;
$color-yellow: #FAA501;
$color-purple: #756EE5;
$checkbox-color-green : #85AC1C;

$color-selection: lighten($color-gray-text, 10%);
$color-orange-hover: darken($color-orange, 10%);
$color-blue-hover: darken($color-blue, 10%);
$color-green-hover: darken($color-green, 10%);
$color-violet-hover: darken($color-violet, 10%);
$color-yellow-hover: darken($color-orange, 10%);
$color-purple-hover: darken($color-purple, 10%);

// Application icons width,height
$icons-md: 101px;
$icons-sm: 85px;