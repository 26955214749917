@import 'settings/variable';  // varijable, mixini i ostalo sta cemo koristit vise puta

// vendor...
// Import Bootstrap and its default variables
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../node_modules/react-datepicker/dist/react-datepicker.css';

// @import 'generic/normalize.scss';
// @import 'generic/box-sizing.scss';
@import 'base/index';               // generalni info cilog layouta
@import 'base/typography';          // generalni stylovi za paragrafe (h1-h6, p, span...)


// components
@import 'components/buttons.scss';
@import 'components/header.scss';
@import 'components/application.scss';
@import 'components/modal.scss';
@import 'components/sidebar.scss';
@import 'components/products-table.scss';
@import 'components/schedule.scss';
@import 'components/tooltip.scss';
@import 'components/toggle-switch.scss';
@import 'components/slider.scss';
@import 'components/shared-link.scss';


// containers
@import 'containers/auth-pages';
@import 'containers/footer.scss';
@import 'containers/home.scss';
@import 'containers/app-wrapper.scss';
